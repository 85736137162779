@charset "UTF-8";

html {
  font-family: eurostile, sans-serif;
  font-size: 1rem;
  overflow-x: hidden;
  line-height: 1.5;
  letter-spacing: 0.025em;
}

body, .webkit-modules{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

body, .webkit-modules{
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

.modular-block {
  position: relative;
}

@media (min-width: 994px) {
  a:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(141, 23, 47, var(--tw-text-opacity));
  }
}

.webkit-spacing-first {
  padding-top: 0px;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-spacing-first{
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-spacing-first{
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-spacing-first{
  margin-top: -50px;
}

@media (max-width: 768px) {
  .webkit-spacing-first {
    margin-top: 0;
  }
}

.webkit-spacing-normal {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-spacing-normal{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-spacing-normal{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-redactor-content {
  font-weight: 400;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  /* em {
    @apply theme('redactor.em');
  } */
}

.webkit-redactor-content h1 {
  font-weight: 700;
  font-size: 3.4375rem;
  margin-top: 0px;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-redactor-content h1{
    font-size: 2.5rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content h1{
    font-size: 1.875rem;
  }
}

.webkit-redactor-content h2 {
  font-weight: 700;
  font-size: 1.75rem;
  margin-top: 0px;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-redactor-content h2{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content h2{
    font-size: 1.25rem;
  }
}

.webkit-redactor-content h3 {
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 0px;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-redactor-content h3{
    font-size: 1.125rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content h3{
    font-size: 1.125rem;
  }
}

.webkit-redactor-content h4 {
  font-weight: 700;
  font-size: 1.25rem;
  margin-top: 0px;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-redactor-content h4{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content h4{
    font-size: 1rem;
  }
}

.webkit-redactor-content h5 {
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 0px;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-redactor-content h5{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content h5{
    font-size: 1rem;
  }
}

.webkit-redactor-content p {
  font-weight: 400;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-redactor-content strong {
  /*  @apply theme('redactor.strong'); */
  padding-top: 1rem;
}

.webkit-redactor-content p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.webkit-redactor-content ol {
  grid-column-start: 2;
  grid-column-end: 23;
  list-style-type: decimal;
  padding-left: 1rem;
}

.webkit-redactor-content ol ::marker {
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  grid-column-start: 2;
}

.webkit-redactor-content ul {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  grid-column-start: 3;
  grid-column-end: 23;
  padding-left: 0.75rem;
}

.webkit-redactor-content ul li {
  position: relative;
  padding-left: 0.5rem;
}

.webkit-redactor-content ul li:before {
  font-weight: 700;
  position: absolute;
  left: -1rem;
  content: "●";
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

a {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

.webkit-doubleGrid-image, .webkit-grid-image, .webkit-quad-block-image, .webkit-testimonial-image, .webkit-gallery-image, .webkit-structureOverview-image, .webkit-oneGrid-image{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.webkit-doubleGrid-image,
.webkit-grid-image,
.webkit-quad-block-image,
.webkit-testimonial-image,
.webkit-gallery-image,
.webkit-structureOverview-image,
.webkit-oneGrid-image{
  transition-duration: 400ms;
}

@media (min-width: 994px) {
  a[href^=https]:hover .webkit-doubleGrid-image, a[href^=https]:hover .webkit-grid-image, a[href^=https]:hover .webkit-quad-block-image, a[href^=https]:hover .webkit-testimonial-image, a[href^=https]:hover .webkit-gallery-image, a[href^=https]:hover .webkit-structureOverview-image, a[href^=https]:hover .webkit-oneGrid-image, a[href^=http]:hover .webkit-doubleGrid-image, a[href^=http]:hover .webkit-grid-image, a[href^=http]:hover .webkit-quad-block-image, a[href^=http]:hover .webkit-testimonial-image, a[href^=http]:hover .webkit-gallery-image, a[href^=http]:hover .webkit-structureOverview-image, a[href^=http]:hover .webkit-oneGrid-image, .webkit-akkordeon-entry:hover .webkit-doubleGrid-image, .webkit-akkordeon-entry:hover .webkit-grid-image, .webkit-akkordeon-entry:hover .webkit-quad-block-image, .webkit-akkordeon-entry:hover .webkit-testimonial-image, .webkit-akkordeon-entry:hover .webkit-gallery-image, .webkit-akkordeon-entry:hover .webkit-structureOverview-image, .webkit-akkordeon-entry:hover .webkit-oneGrid-image{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  a[href^=https]:hover .webkit-btn__big, a[href^=https]:hover .webkit-quad-block-button, a[href^=http]:hover .webkit-btn__big, a[href^=http]:hover .webkit-quad-block-button, .webkit-akkordeon-entry:hover .webkit-btn__big, .webkit-akkordeon-entry:hover .webkit-quad-block-button{
    --tw-bg-opacity: 1;
    background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  a[href^=https]:hover .webkit-btn__small, a[href^=http]:hover .webkit-btn__small, .webkit-akkordeon-entry:hover .webkit-btn__small{
    --tw-bg-opacity: 1;
    background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  a[href^=https]:hover .webkit-btn__small i, a[href^=http]:hover .webkit-btn__small i, .webkit-akkordeon-entry:hover .webkit-btn__small i{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-arrow-down:before, .webkit-plus:before,
.webkit-angleDown:before, .webkit-minus:before,
.webkit-angleUp:before, .webkit-arrow-right:before, .webkit-play-button:before {
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

i:before {
  vertical-align: middle;
}

i.fa-angle-right:before {
  vertical-align: top;
}

.webkit-play-button:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f144";
}

.webkit-arrow-right:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f105";
  line-height: 0.5;
  margin-left: 2px;
  margin-top: 1px;
  font-size: 1rem;
}

@media (max-width: 993px){
  .webkit-arrow-right:before{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-arrow-right:before{
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (max-width: 480px) {
  .webkit-arrow-right:before {
    margin-bottom: 0px;
  }
}

.webkit-minus:before, .webkit-angleUp:before{
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f106";
}

.webkit-minus:before,
.webkit-angleUp:before{
  font-size: 1rem;
  line-height: 0.5;
  margin-bottom: 1px;
}

@media (max-width: 993px){
  .webkit-minus:before, .webkit-angleUp:before{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-minus:before, .webkit-angleUp:before{
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.webkit-plus:before, .webkit-angleDown:before{
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f107";
}

.webkit-plus:before,
.webkit-angleDown:before{
  font-size: 1rem;
  line-height: 0.5;
  margin-right: 1px;
  margin-top: 3px;
}

@media (max-width: 993px){
  .webkit-plus:before, .webkit-angleDown:before{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-plus:before, .webkit-angleDown:before{
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.webkit-angleDown:before, .webkit-angleUp:before{
  margin: 0px;
}

.webkit-arrow-down:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
  content: "\f063";
  line-height: 0.5;
  margin-bottom: 1px;
  font-size: 0.775rem;
}

@media (max-width: 480px){
  .webkit-arrow-down:before{
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (max-width: 480px) {
  .webkit-arrow-down:before {
    margin-bottom: 0px;
  }
}

.webkit-loadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.webkit-loadingSpinner:before {
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

button {
  outline: none !important;
}

.webkit-btn__reversed, .webkit-btn__icon, .webkit-btn__small, .webkit-btn__big, .webkit-header-button, .webkit-btn {
  border-width: 1px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 400ms;
  --tw-border-opacity: 1;
  border-color: rgba(141, 23, 47, var(--tw-border-opacity));
  border-width: 2px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  font-weight: 400;
  font-weight: 700;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.webkit-btn__reversed:hover, .webkit-btn__icon:hover, .webkit-btn__small:hover, .webkit-btn__big:hover, .webkit-header-button:hover, .webkit-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border: 2px solid #fff;
}

.webkit-btn__big, .webkit-header-button {
  overflow: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

@media (max-width: 993px){
  .webkit-btn__big, .webkit-header-button{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__big, .webkit-header-button{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.webkit-btn__big, .webkit-header-button{
  border-radius: 0.125rem;
}

.webkit-btn__small {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2rem;
  width: 2.2rem;
}

@media (max-width: 993px){
  .webkit-btn__small{
    height: 1.75rem;
    width: 1.75rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small{
    height: 1.5rem;
    width: 1.5rem;
  }
}

.webkit-btn__small{
  border-radius: 1.5rem;
}

.webkit-btn__icon {
  border-style: none;
  display: flex;
  font-size: 1.5rem;
}

@media (max-width: 1280px){
  .webkit-btn__icon{
    font-size: 1.5rem;
  }
}

@media (max-width: 993px){
  .webkit-btn__icon{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__icon{
    font-size: 1rem;
  }
}

.webkit-btn__icon{
  align-items: center;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

.webkit-btn__icon .btn-icon-text:after {
  content: "";
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  display: block;
  opacity: 0;
  width: 100%;
  height: 1px;
}

.webkit-btn__icon i {
  font-size: 1.5rem;
  padding-left: 0.5rem;
}

.webkit-btn__reversed {
  overflow: hidden;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

@media (max-width: 993px){
  .webkit-btn__reversed{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__reversed{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.webkit-btn__reversed{
  border-radius: 0.125rem;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

/* .main-btn{
  @apply text-f6 font-bold py-3 px-5 text-color3;
  border:2px solid #8d172f;
  @apply py-2;

  &:hover{
    @apply bg-color3 text-color2;
  }
} */

.btn-white {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border: 2px solid white;
}

.btn-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  border: 2px solid #8d172f;
}

.btn-white-black {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border: 2px solid white;
}

.btn-white-black:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  border: 2px solid #8d172f;
}

.btn-contact-overlay {
  display: none;
  font-size: 1.5rem;
  margin: 0.75rem;
  margin-right: 1.25rem;
}

@media (max-width: 768px){
  .btn-contact-overlay{
    display: block;
  }
}

.btn-contact-overlay{
  background-color: transparent;
  position: absolute;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  width: 90%;
  top: 345px;
  padding-left: 1.2rem;
}

.btn-contact-overlay:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.webkit-doubleBlock-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.webkit-doubleBlock-outer-grid {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(24, 1fr);
  /*  @apply max-w-content mx-auto; */
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-doubleBlock-outer-grid{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-doubleBlock-outer-grid{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

@media (max-width: 768px) {
  .webkit-doubleBlock-outer-grid {
    min-height: 400px;
  }
}

@media (max-width: 480px) {
  .webkit-doubleBlock-outer-grid {
    height: 100%;
  }
}

.webkit-doubleBlock-outer-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

.webkit-doubleBlock-grid {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1280px) {
  .webkit-doubleBlock-grid {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock{
    grid-row-start: 1;
  }
}

@media (max-width: 480px){
  .webkit-doubleBlock{
    grid-row-start: 2;
  }
}

.webkit-doubleBlock:nth-child(1).webkit-double-image-container, .webkit-doubleBlock:nth-child(1).webkit-double-video-container, .webkit-doubleBlock:nth-child(1).webkit-double-text, .webkit-doubleBlock:nth-child(1).webkit-double-list {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

@media (max-width: 480px){
  .webkit-doubleBlock:nth-child(1).webkit-double-image-container, .webkit-doubleBlock:nth-child(1).webkit-double-video-container, .webkit-doubleBlock:nth-child(1).webkit-double-text, .webkit-doubleBlock:nth-child(1).webkit-double-list{
    grid-column: span 2 / span 2;
    grid-column-start: 1;
  }
}

.webkit-doubleBlock:nth-child(2).webkit-double-image-container, .webkit-doubleBlock:nth-child(2).webkit-double-video-container, .webkit-doubleBlock:nth-child(2).webkit-double-text, .webkit-doubleBlock:nth-child(2).webkit-double-list {
  grid-column: span 1 / span 1;
  grid-column-start: 2;
}

@media (max-width: 480px){
  .webkit-doubleBlock:nth-child(2).webkit-double-image-container, .webkit-doubleBlock:nth-child(2).webkit-double-video-container, .webkit-doubleBlock:nth-child(2).webkit-double-text, .webkit-doubleBlock:nth-child(2).webkit-double-list{
    grid-column: span 2 / span 2;
    grid-column-start: 1;
  }
}

.bg-img__bg, .webkit-slide-image, .bg-img__square, .bg-img__2-1, .bg-img__4-3, .bg-img__16-10, .bg-img__16-9, .bg-img, .webkit-gallery-image, .webkit-grid-image, .webkit-doubleGrid-image, .webkit-double-video-thumbnail-aspect {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img__contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.img-hidden {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}

.bg-img__16-9 {
  padding-top: 56.25%;
}

.bg-img__16-10 {
  padding-top: 62.5%;
}

.bg-img__4-3 {
  padding-top: 75%;
}

.bg-img__2-1 {
  padding-top: 50%;
}

.bg-img__square {
  padding-top: 100%;
  border-radius: 1rem;
}

.bg-img__round {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 666px;
  padding-top: 100%;
}

.bg-img__center-top {
  background-position: top;
}

.bg-img__bg:after, .webkit-slide-image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  opacity: 0.5;
}

.webkit-double-image-container {
  align-self: center;
  height: 100%;
  padding: 0px;
}

@media (max-width: 480px){
  .webkit-double-image-container{
    order: -9999;
    height: auto;
  }
}

.webkit-double-image-container{
  /*   @apply shadow-lg xs:shadow-none; */
}

@media (max-width: 480px) {
  .webkit-double-image-container {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

.webkit-double-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.webkit-double-image-aspect {
  padding-top: 100%;
}

.large-img {
  width: 55vw;
}

.big-img-width {
  height: 550px;
}

@media (max-width: 1280px) {
  .big-img-width {
    width: 100%;
    height: 300px;
  }
}

.webkit-double-list {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  align-self: center;
  height: 100%;
  padding: 8.333333333333332%;
}

@media (max-width: 480px){
  .webkit-double-list{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    order: 9999;
    padding-left: 8.333333333333332%;
    padding-right: 8.333333333333332%;
  }
}

.webkit-double-list{
  /* box-shadow: 2px 4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06); */
  z-index: 10;
}

@media (max-width: 768px){
  .webkit-double-list{
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 480px){
  .webkit-double-list{
    position: relative;
  }
}

.webkit-double-list{
  position: relative;
}

@media (max-width: 1280px) {
  .webkit-double-list {
    width: 100%;
  }
}

@media (max-width: 993px) {
  .webkit-double-list {
    padding-bottom: 0px;
  }
}

.webkit-double-list-title {
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-double-list-title{
    font-size: 1.125rem;
  }
}

@media (max-width: 480px){
  .webkit-double-list-title{
    font-size: 1.125rem;
  }
}

@media (max-width: 993px){
  .webkit-double-list-title{
    margin-bottom: 4.166666666666666%;
  }
}

.webkit-double-list-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: grid;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
  flex-direction: row-reverse;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

@media (max-width: 480px) {
  .webkit-double-list-link + .webkit-double-list-link {
    padding-top: 1rem;
  }
}

.webkit-double-list-label {
  align-self: center;
}

.webkit-double-list-button {
  align-self: center;
  order: -9999;
  margin-right: 1rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-double-list-button{
    margin-right: 0.5rem;
  }
}

.webkit-double-list-button{
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

.webkit-doubleBlock-list-container {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 8.3333333333%;
  left: 8.3333333333%;
}

@media (max-width: 480px) {
  .webkit-doubleBlock-list-container {
    position: relative;
    transform: translate(0, 0);
    top: 0;
    right: 0;
    left: 0;
  }
}

.webkit-heading-list {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.webkit-double-text {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  align-content: center;
  align-self: center;
  height: 100%;
  padding: 8.333333333333332%;
}

@media (max-width: 768px){
  .webkit-double-text{
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 480px){
  .webkit-double-text{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding-left: 8.333333333333332%;
    padding-right: 8.333333333333332%;
  }
}

.webkit-double-text{
  /* box-shadow: 2px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 -2px 4px -1px rgba(0, 0, 0, 0.06); */
  z-index: 10;
}

@media (max-width: 480px){
  .webkit-double-text{
    position: relative;
  }
}

.webkit-double-text{
  position: relative;
}

@media (max-width: 480px) {
  .webkit-double-text {
    order: -1;
    padding: 10px 0;
  }
}

.webkit-double-block-text {
  width: 100%;
  padding-top: 20%;
}

@media (max-width: 1280px) {
  .webkit-double-block-text {
    padding: 20%;
  }
}

@media (max-width: 993px) {
  .webkit-double-block-text {
    padding: 30%;
  }
}

@media (max-width: 480px) {
  .webkit-double-block-text {
    padding: 0%;
  }
}

.webkit-double-text-grid {
  display: grid;
  /* grid-template-rows: max-content 1fr max-content; */
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 8.3333333333%;
  left: 8.3333333333%;
}

@media (max-width: 768px) {
  .webkit-double-text-grid {
    left: 0;
  }
}

@media (max-width: 480px) {
  .webkit-double-text-grid {
    position: relative;
    transform: translate(0, 0);
    top: 0;
    right: 0;
    left: 0;
  }
}

.webkit-double-text-title {
  align-self: flex-end;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-double-text-title{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-double-text-title{
    font-size: 1.25rem;
  }
}

.webkit-double-text-title{
  font-weight: 700;
  line-height: 2.5rem;
  margin-bottom: 1.25rem;
}

@media (max-width: 768px){
  .webkit-double-text-title{
    line-height: 1.75rem;
    margin-bottom: 0px;
    margin-top: 1rem;
  }
}

.webkit-double-text-content {
  font-weight: 400;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

@media (max-width: 993px){
  .webkit-double-text-content{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

@media (max-width: 480px){
  .webkit-double-text-content{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

.webkit-double-text-btn {
  justify-self: start;
  margin-top: 1rem;
}

@media (max-width: 768px){
  .webkit-double-text-btn{
    margin-top: 0.5rem;
  }
}

.large-text {
  width: 55vw;
}

@media (max-width: 1280px) {
  .large-text {
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 993px) {
  .large-text {
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 480px) {
  .large-text {
    height: 100%;
  }
}

.webkit-double-video-container {
  align-self: center;
  overflow: hidden;
}

@media (max-width: 480px){
  .webkit-double-video-container{
    order: -9999;
  }
}

.webkit-double-video-container{
  height: 100%;
  position: relative;
}

@media (max-width: 480px){
  .webkit-double-video-container{
    height: auto;
  }
}

.webkit-double-video {
  position: absolute;
  width: 1280px;
  height: 720px;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

@media (max-width: 1600px) {
  .webkit-double-video {
    width: 1200px;
    height: 675px;
  }
}

@media (max-width: 1280px) {
  .webkit-double-video {
    height: 100%;
    width: 100%;
  }
}

.webkit-double-video-thumbnail {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  position: relative;
}

@media (max-width: 480px){
  .webkit-double-video-thumbnail{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

.webkit-double-video-play {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

.webkit-double-video-play-button {
  font-size: 2.5rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

.webkit-double-video-play-text {
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-double-video-play-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-double-video-play-text{
    font-size: 1rem;
  }
}

.webkit-double-video-thumbnail-aspect {
  background-size: cover;
  height: 100%;
  padding-top: 100%;
}

.webkit-double-video-overlay-container {
  position: absolute;
  max-width: 1600px;
}

@media (max-width: 1280px){
  .webkit-double-video-overlay-container{
    height: 0px;
    padding-top: 56.25%;
  }
}

.webkit-double-video-overlay-container{
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  width: 100%;
}

.webkit-double-video-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  cursor: pointer;
  overflow: hidden;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

.webkit-double-video-overlay-close {
  position: absolute;
  top: -20rem;
  right: -1rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 1600px) {
  .webkit-double-video-overlay-close {
    top: -20rem;
    right: 0;
  }
}

@media (max-width: 1280px) {
  .webkit-double-video-overlay-close {
    top: -12%;
    right: 10px;
  }
}

@media (max-width: 480px) {
  .webkit-double-video-overlay-close {
    top: -14%;
  }
}

.webkit-akkordeon-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-akkordeon-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-akkordeon {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-akkordeon .download-grid-container {
  margin-bottom: 1rem;
}

.webkit-akkordeon-heading-container {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
}

.webkit-akkordeon-heading {
  padding-bottom: 2.1rem;
  grid-column-start: 1;
  grid-column-end: 20;
  padding-top: 1rem;
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-akkordeon-heading{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-heading{
    font-size: 1.25rem;
  }
}

@media (max-width: 993px) {
  .webkit-akkordeon-heading {
    padding-bottom: 1.65rem;
  }
}

@media (max-width: 480px) {
  .webkit-akkordeon-heading {
    padding-bottom: 1.25rem;
  }
}

.webkit-akkordeon-heading::after {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  border-radius: 0.125rem;
  display: block;
  height: 0.25rem;
  width: 1.5rem;
  content: "";
}

.webkit-akkordeon-entry {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 1rem;
}

.webkit-akkordeon-entry + .webkit-akkordeon-entry {
  margin-top: 1rem;
}

.webkit-akkordeon-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 768px){
  .webkit-akkordeon-title{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-title{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.webkit-akkordeon-title{
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(18, 1fr);
}

.webkit-akkordeon-title-text {
  align-self: center;
  padding-right: 0.5rem;
  grid-column-start: 2;
  grid-column-end: 17;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-akkordeon-title-text{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-title-text{
    font-size: 1rem;
  }
}

.webkit-akkordeon-text-container {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.webkit-akkordeon-text {
  padding-bottom: 2rem;
  grid-column-start: 2;
  grid-column-end: 17;
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-akkordeon-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-text{
    font-size: 1rem;
  }
}

.webkit-akkordeon-block {
  grid-column: span 14 / span 14;
  grid-column-start: 6;
}

@media (max-width: 768px){
  .webkit-akkordeon-block{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-akkordeon-icon-container {
  align-self: center;
  justify-self: end;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px) {
  .webkit-akkordeon-icon-container .webkit-btn__small {
    transition-property: none;
  }
}

.new-tab-container {
  display: flex;
  width: 100%;
  margin: 0px auto;
  margin-bottom: 30px;
}

.open-new-tab {
  padding: 10px;
  justify-content: center;
  border-radius: 1rem;
  line-height: 1rem;
  font-size: 0.75rem;
  padding: 15px;
  margin: 10px 0;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.open-new-tab:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-banner-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-banner-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 993px){
  .webkit-banner-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-banner {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  grid-template-rows: -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content;
}

.webkit-banner-button {
  justify-self: end;
  margin-top: 4.166666666666666%;
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-banner-button{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-banner-text {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-banner-text{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-banner-text{
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-banner-text{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-banner-text{
    font-size: 1.25rem;
  }
}

.webkit-banner-title {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin-bottom: 0.5rem;
  /* @apply m:pb-4; */
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-banner-title{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-banner-title{
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-banner-title{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-banner-title{
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .webkit-banner-title {
    /* padding-bottom: 0.75rem; */
  }
}

.webkit-newsletter-container {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  position: relative;
  z-index: 10;
}

.webkit-newsletter-grid {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-newsletter-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.webkit-newsletter {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

.webkit-newsletter-text {
  align-self: flex-start;
  padding-right: 1rem;
  grid-column: span 11 / span 11;
}

@media (max-width: 768px){
  .webkit-newsletter-text{
    grid-column: span 20 / span 20;
  }
}

@media (max-width: 768px){
  .webkit-newsletter-text{
    padding-right: 0px;
    padding-bottom: 4.166666666666666%;
    text-align: center;
  }
}

@media (max-width: 480px){
  .webkit-newsletter-text{
    padding-right: 0px;
    padding-bottom: 4.166666666666666%;
    text-align: left;
  }
}

.webkit-newsletter-text{
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-newsletter-text{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-newsletter-text{
    font-size: 1.25rem;
  }
}

.webkit-newsletter-form-container {
  grid-column: span 9 / span 9;
  grid-column-start: 12;
}

@media (max-width: 768px){
  .webkit-newsletter-form-container{
    grid-column: span 14 / span 14;
    grid-column-start: 4;
    grid-row-start: 2;
  }
}

@media (max-width: 480px){
  .webkit-newsletter-form-container{
    grid-column: span 20 / span 20;
    grid-column-start: 1;
  }
}

.webkit-newsletter-form {
  display: grid;
  height: 100%;
  width: 100%;
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-newsletter-form{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-newsletter-form{
    font-size: 1rem;
  }
}

.webkit-newsletter-form{
  grid-template-columns: 1fr -webkit-max-content;
  grid-template-columns: 1fr max-content;
}

@media (max-width: 768px) {
  .webkit-newsletter-form {
    margin: 0 auto;
  }
}

.webkit-newsletter-input {
  cursor: text;
  align-self: center;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-style: none;
  padding-left: 1rem;
  height: 2.5rem;
}

@media (max-width: 993px){
  .webkit-newsletter-input{
    height: 2.2rem;
  }
}

@media (max-width: 768px){
  .webkit-newsletter-input{
    height: 2rem;
  }
}

.webkit-newsletter-input{
  display: inline-block;
  line-height: 1;
  min-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  caret-color: #8d172f;
}

.webkit-newsletter-input::-moz-placeholder {
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-newsletter-input::-moz-placeholder{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-newsletter-input::-moz-placeholder{
    font-size: 1rem;
  }
}

.webkit-newsletter-input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-newsletter-input:-ms-input-placeholder{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-newsletter-input:-ms-input-placeholder{
    font-size: 1rem;
  }
}

.webkit-newsletter-input::-moz-placeholder {
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-newsletter-input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-newsletter-input::placeholder {
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-newsletter-input::-moz-placeholder{
    font-size: 1rem;
  }
  .webkit-newsletter-input:-ms-input-placeholder{
    font-size: 1rem;
  }
  .webkit-newsletter-input::placeholder{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-newsletter-input::-moz-placeholder{
    font-size: 1rem;
  }
  .webkit-newsletter-input:-ms-input-placeholder{
    font-size: 1rem;
  }
  .webkit-newsletter-input::placeholder{
    font-size: 1rem;
  }
}

.webkit-newsletter-button i {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 993px){
  .webkit-newsletter-button i{
    height: 2.2rem;
    width: 2.2rem;
  }
}

@media (max-width: 768px){
  .webkit-newsletter-button i{
    height: 2rem;
    width: 2rem;
  }
}

@media (max-width: 480px){
  .webkit-newsletter-button i{
    height: 2rem;
    width: 2rem;
  }
}

.webkit-newsletter-button i{
  /* @apply theme('bannerNewsletter.icon'); */
}

.webkit-newsletter-button {
  cursor: pointer;
  align-self: center;
  height: 100%;
}

.webkit-newsletter-button i {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  font-size: .7rem;
}

.webkit-newsletter-button i:before {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 200ms;
}

@media (min-width: 994px) {
  .webkit-newsletter-button:hover .webkit-newsletter-send-icon {
    --tw-bg-opacity: 1;
    background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  }

  .webkit-newsletter-button:hover .webkit-newsletter-send-icon:before {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
}

.webkit-newsletter-error {
  padding-top: 0.5rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-newsletter-error{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-newsletter-error{
    font-size: 1.25rem;
  }
}

.webkit-newsletter-button-success, .webkit-loadingSpinner:hover, .webkit-newsletter-button-success:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-newsletter-button-success, .webkit-loadingSpinner:hover, .webkit-newsletter-button-success:hover{
  cursor: default;
}

.webkit-newsletter-send-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  display: flex;
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  font-weight: 700;
  font-size: 1.5rem;
}

@media (max-width: 993px){
  .webkit-newsletter-send-icon{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-newsletter-send-icon{
    font-size: 1rem;
  }
}

.webkit-contact-banner-container {
  display: grid;
  margin-top: 2.5rem;
  position: relative;
  z-index: 10;
  grid-template-columns: repeat(24, 1fr);
}

@media (max-width: 1280px){
  .webkit-contact-banner-container{
    margin-top: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-contact-banner-container{
    margin-top: 8.333333333333332%;
  }
}

.webkit-contact-banner-container{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 10px 2px bg-color2;
}

.webkit-contact-banner-outer-cell {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
}

.webkit-contact-banner-bg-img {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  padding-top: 33.33333333333333%;
}

.webkit-contact-banner-grid {
  /*   @apply max-w-content mx-auto; */
  display: grid;
  height: 100%;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-contact-banner-cell {
  align-self: center;
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-contact-banner-cell{
    grid-column: span 22 / span 22;
    grid-column-start: 3;
  }
}

.webkit-contact-banner {
  padding-top: 2.5rem;
  padding-bottom: 5rem;
}

@media (max-width: 480px){
  .webkit-contact-banner{
    padding-bottom: 1.25rem;
    padding-top: 2.5rem;
  }
}

.webkit-contact-banner{
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1280px) {
  .webkit-contact-banner {
    flex-direction: column;
  }
}

.webkit-contact-banner-text {
  width: 50%;
  align-self: center;
  padding-right: 1rem;
}

@media (max-width: 768px){
  .webkit-contact-banner-text{
    padding-right: 0px;
    padding-bottom: 4.166666666666666%;
    text-align: center;
  }
}

.webkit-contact-banner-text{
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-contact-banner-text{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-contact-banner-text{
    font-size: 1.25rem;
  }
}

.webkit-contact-banner-text{
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

@media (max-width: 480px){
  .webkit-contact-banner-text{
    font-size: 1.25rem;
  }
}

@media (max-width: 1280px) {
  .webkit-contact-banner-text {
    text-align: left;
    flex-basis: 100%;
    width: 100%;
  }
}

.webkit-contact-banner-actions {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
}

@media (max-width: 768px){
  .webkit-contact-banner-actions{
    margin-top: 0px;
  }
}

@media (max-width: 1280px) {
  .webkit-contact-banner-actions {
    margin-top: 1.25rem;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .webkit-contact-banner-actions {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @media (max-width: 768px){
    .webkit-contact-banner-actions{
      margin-top: 0px;
    }
  }
}

.webkit-contact-banner-button {
  background-color: transparent;
  flex: none;
  font-weight: 700;
  margin-right: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-contact-banner-button{
    text-align: center;
  }
}

@media (max-width: 480px){
  .webkit-contact-banner-button{
    margin-right: 0.25rem;
  }
}

.webkit-contact-banner-button{
  border: 2px solid #8D172F;
  font-size: 22px;
  margin-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 768px){
  .webkit-contact-banner-button{
    margin-bottom: 0.75rem;
  }
}

.webkit-contact-banner-button:hover {
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.webkit-contact-banner-button i:before {
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  font-size: 14px;
}

.button-color-without-img {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

@media (min-width: 994px){
  .button-color-without-img:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }
}

.button-color-with-img {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  border-style: none;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (min-width: 994px){
  .button-color-with-img:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
}

.webkit-doubleGrid-text, .webkit-doubleGrid-title, .webkit-doubleGrid-heading {
  text-align: left;
}

.webkit-doubleGrid-background {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-doubleGrid-background{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-doubleGrid-background{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-doubleGrid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-doubleGrid-container-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-doubleGrid-container-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-doubleGrid-parent {
  border-radius: 1rem;
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 768px){
  .webkit-doubleGrid-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-doubleGrid-parent{
  gap: 2rem;
}

@media (max-width: 993px){
  .webkit-doubleGrid-parent{
    gap: 1rem;
  }
}

.webkit-doubleGrid-item {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 1rem;
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  padding-bottom: 1rem;
  position: relative;
  z-index: 10;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
}

.webkit-doubleGrid-item-grid {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content 1fr -webkit-max-content;
  grid-template-rows: max-content max-content max-content 1fr max-content;
}

.webkit-doubleGrid-item-grid > * {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

.webkit-doubleGrid-cell-background {
  border-radius: 1rem;
  grid-row: span 5 / span 5;
  grid-row-start: 1;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-width: 0px;
}

.webkit-doubleGrid-cell-img {
  margin-bottom: 1rem;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
}

@media (max-width: 768px){
  .webkit-doubleGrid-cell-img{
    margin-bottom: 8.333333333333332%;
  }
}

.webkit-doubleGrid-cell-img{
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
}

.webkit-doubleGrid-image {
  width: 100%;
  padding-top: 62.5%;
}

@media (max-width: 993px){
  .webkit-doubleGrid-image{
    padding-top: 75%;
  }
}

@media (max-width: 768px){
  .webkit-doubleGrid-image{
    padding-top: 56.25%;
  }
}

.webkit-doubleGrid-heading {
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  font-weight: 700;
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-doubleGrid-heading{
    font-size: 1.125rem;
  }
}

@media (max-width: 480px){
  .webkit-doubleGrid-heading{
    font-size: 1.125rem;
  }
}

.webkit-doubleGrid-heading{
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
  padding-top: 0.5rem;
}

.webkit-doubleGrid-title {
  grid-row: span 1 / span 1;
  grid-row-start: 3;
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
  padding-top: 0.25rem;
  padding-bottom: 0.75rem;
}

@media (max-width: 480px){
  .webkit-doubleGrid-title{
    padding-bottom: 0.5rem;
  }
}

.webkit-doubleGrid-text {
  grid-row: span 1 / span 1;
  grid-row-start: 4;
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-doubleGrid-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-doubleGrid-text{
    font-size: 1rem;
  }
}

.webkit-doubleGrid-text{
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
}

.webkit-doubleGrid-button-cell {
  grid-row: span 1 / span 1;
  grid-row-start: 5;
}

.webkit-doubleGrid-button {
  display: flex;
  align-self: flex-end;
  margin-top: 1rem;
  margin-right: 1rem;
  justify-content: flex-end;
}

.webkit-oneGrid-text, .webkit-oneGrid-title, .webkit-oneGrid-heading {
  text-align: left;
}

.webkit-oneGrid-background {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-oneGrid-background{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-oneGrid-background{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-oneGrid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-oneGrid-container-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-oneGrid-container-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-oneGrid-parent {
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  grid-template-columns: repeat(1, 1fr);
}

@media (max-width: 768px){
  .webkit-oneGrid-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-oneGrid-parent{
  gap: 2rem;
}

@media (max-width: 993px){
  .webkit-oneGrid-parent{
    gap: 1rem;
  }
}

.webkit-oneGrid-item {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

@media (max-width: 768px){
  .webkit-oneGrid-item{
    padding: 0px;
  }
}

.webkit-oneGrid-item{
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  position: relative;
  z-index: 10;
  /*   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
}

.webkit-oneGrid-item-grid {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: repeat(24, 1fr);
}

@media (max-width: 768px){
  .webkit-oneGrid-item-grid{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-oneGrid-item-grid{
  grid-template-rows: -webkit-max-content 1fr -webkit-max-content;
  grid-template-rows: max-content 1fr max-content;
}

@media (max-width: 768px){
  .webkit-oneGrid-item-grid > *{
    grid-column: span 1 / span 1;
    grid-column-start: 1;
  }
}

.webkit-oneGrid-cell-background {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row: span 5 / span 5;
  grid-row-start: 1;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 0px;
}

.webkit-oneGrid-cell-img {
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  grid-column: span 8 / span 8;
  grid-column-start: 1;
}

@media (max-width: 768px){
  .webkit-oneGrid-cell-img{
    grid-column: span 1 / span 1;
    grid-column-start: 1;
  }
}

.webkit-oneGrid-cell-img{
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  overflow: hidden;
}

@media (max-width: 768px){
  .webkit-oneGrid-cell-img{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

.webkit-oneGrid-image {
  height: 100%;
  width: 100%;
  padding-top: 75%;
  background-size: cover;
}

.webkit-oneGrid-content {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0px;
  padding-left: 2rem;
  padding-top: 8.333333333333332%;
}

@media (max-width: 768px){
  .webkit-oneGrid-content{
    padding: 1.25rem;
  }
}

.webkit-oneGrid-content{
  grid-row: span 1 / span 1;
  grid-row-start: 1;
}

@media (max-width: 768px){
  .webkit-oneGrid-content{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    grid-row-start: 2;
  }
}

.webkit-oneGrid-content{
  grid-column: span 16 / span 16;
  grid-column-start: 9;
}

@media (max-width: 768px){
  .webkit-oneGrid-content{
    grid-column: span 1 / span 1;
    grid-column-start: 1;
  }
}

.webkit-oneGrid-heading {
  padding-bottom: 0.5rem;
  grid-row: span 1 / span 1;
  grid-row-start: 3;
  font-weight: 700;
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-oneGrid-heading{
    font-size: 1.125rem;
  }
}

@media (max-width: 480px){
  .webkit-oneGrid-heading{
    font-size: 1.125rem;
  }
}

.webkit-oneGrid-title {
  grid-row: span 1 / span 1;
  grid-row-start: 3;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-oneGrid-text {
  grid-row: span 1 / span 1;
  grid-row-start: 4;
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-oneGrid-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-oneGrid-text{
    font-size: 1rem;
  }
}

.webkit-oneGrid-button-cell {
  padding-bottom: 0.5rem;
  grid-row: span 1 / span 1;
  grid-row-start: 5;
}

.webkit-oneGrid-button {
  display: flex;
  align-self: flex-end;
  margin-top: 1rem;
  margin-right: 1rem;
}

@media (max-width: 768px){
  .webkit-oneGrid-button{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-top: 1rem;
  }
}

.webkit-oneGrid-button{
  justify-content: flex-end;
}

.webkit-grid-button, .webkit-grid-text, .webkit-grid-heading, .webkit-grid-news-heading, .webkit-grid-datum, .webkit-grid-heading2 {
  justify-content: flex-start;
  text-align: left;
}

.webkit-grid-title {
  text-align: left;
  grid-column: span 20 / span 20;
  grid-column-start: 4;
  padding-bottom: 2.1rem;
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-grid-title{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-title{
    font-size: 1.25rem;
  }
}

@media (max-width: 993px) {
  .webkit-grid-title {
    padding-bottom: 1.65rem;
  }
}

@media (max-width: 480px) {
  .webkit-grid-title {
    padding-bottom: 1.25rem;
  }
}

.webkit-grid-title::after {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  border-radius: 0.125rem;
  display: block;
  height: 0.25rem;
  width: 1.5rem;
  content: "";
}

.webkit-grid-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-grid-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-grid-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-grid-container-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 4;
  background-image: none;
}

.webkit-grid-parent {
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  gap: 1.5rem;
}

@media (max-width: 993px){
  .webkit-grid-parent{
    gap: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-parent{
    gap: 0.75rem;
  }
}

.webkit-grid-parent{
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1600px){
  .webkit-grid-parent{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1280px){
  .webkit-grid-parent{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 993px){
  .webkit-grid-parent{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px){
  .webkit-grid-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px){
  .webkit-grid-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-grid-item {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 1rem;
  position: relative;
  z-index: 10;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
}

.webkit-grid-item-grid {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content 1fr -webkit-max-content;
  grid-template-rows: max-content max-content max-content max-content 1fr max-content;
}

.webkit-grid-item-grid > * {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

.webkit-grid-cell-background {
  grid-row: span 6 / span 6;
  grid-row-start: 1;
  background-color: transparent;
}

.webkit-grid-cell-img {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  overflow: hidden;
}

.webkit-grid-image {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 100%;
  padding-top: 75%;
}

@media (max-width: 993px){
  .webkit-grid-image{
    padding-top: 75%;
  }
}

@media (max-width: 480px){
  .webkit-grid-image{
    padding-top: 66.66666666666666%;
  }
}

.webkit-grid-spacing-above-text {
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  padding-bottom: 1rem;
}

.webkit-grid-cell-heading2 {
  padding-bottom: 0.25rem;
  grid-row: span 1 / span 1;
  grid-row-start: 3;
}

.webkit-grid-heading2 {
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-grid-heading2{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-heading2{
    font-size: 1.25rem;
  }
}

.webkit-grid-datum {
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
  padding-top: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-grid-news-heading {
  grid-row: span 1 / span 1;
  grid-row-start: 4;
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-grid-news-heading{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-news-heading{
    font-size: 1.125rem;
  }
}

.webkit-grid-heading {
  grid-row: span 1 / span 1;
  grid-row-start: 4;
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-grid-heading{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-heading{
    font-size: 1.125rem;
  }
}

.webkit-grid-text {
  grid-row: span 1 / span 1;
  grid-row-start: 5;
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
  padding-bottom: 1rem;
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-grid-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-text{
    font-size: 1rem;
  }
}

.webkit-grid-button-cell {
  grid-row: span 1 / span 1;
  grid-row-start: 6;
}

.webkit-grid-button {
  display: flex;
  flex-direction: row-reverse;
  align-self: flex-end;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.webkit-grid-load-more-button-container {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 1rem;
}

.webkit-heading-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  max-width: none;
  grid-column-start: 4;
}

.webkit-heading {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: 0px;
  margin-right: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  margin-bottom: 6rem;
  text-align: left;
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-heading{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-heading{
    font-size: 1.25rem;
  }
}

.webkit-heading{
  font-weight: 700;
  margin-left: 0px;
  margin-right: 0px;
}

@media (max-width: 1280px) {
  .webkit-heading {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.webkit-heading-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-heading-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-heading-cell{
  font-weight: 900;
}

@media (max-width: 1280px){
  .webkit-heading-cell{
    grid-column-start: 3;
  }
}

.webkit-heading-cell:after {
  content: "";
}

.webkit-iconList-container {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-iconList-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 768px){
  .webkit-iconList-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
    padding-bottom: 0px;
  }
}

.webkit-iconList {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column: span 20 / span 20;
  grid-column-start: 3;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.webkit-iconList-item-grid {
  display: grid;
  justify-content: center;
}

@media (max-width: 768px){
  .webkit-iconList-item-grid{
    display: flex;
  }
}

.webkit-iconList-item-grid{
  /* grid-template-columns: max-content 1fr; */
}

.webkit-iconList-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 993px){
  .webkit-iconList-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 768px){
  .webkit-iconList-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 768px){
  .webkit-iconList-cell{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.webkit-iconList-item-container {
  display: flex;
  align-items: center;
}

@media (max-width: 768px){
  .webkit-iconList-item-container{
    flex-direction: column;
    align-items: flex-start;
  }
}

.webkit-iconList-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  flex-basis: 30%;
}

.webkit-iconList-item:nth-child(even) {
  /* margin-left: 10%; */
}

@media (max-width: 993px) {
  .webkit-iconList-item:nth-child(even) {
    flex-basis: 47.5%;
    margin-left: 5%;
  }
}

@media (max-width: 768px) {
  .webkit-iconList-item:nth-child(even) {
    flex-basis: 100%;
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .webkit-iconList-item {
    flex-basis: 100%;
  }
}

.webkit-iconList-iconContainer {
  background-repeat: no-repeat;
  background-size: contain;
  align-self: center;
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 480px){
  .webkit-iconList-iconContainer{
    height: 2rem;
    width: 2rem;
  }
}

.webkit-iconList-iconContainer{
  /* @apply theme('iconList.iconBackgroundColor'); */
  left: 50%;
}

@media (max-width: 768px){
  .webkit-iconList-iconContainer{
    left: 0px;
  }
}

.webkit-iconList-iconContainer{
  transform: translate(-50%);
}

@media (max-width: 768px) {
  .webkit-iconList-iconContainer {
    transform: translate(0%);
  }

  @media (max-width: 768px){
    .webkit-iconList-iconContainer{
      margin-right: 1.25rem;
    }
  }
}

.webkit-iconList-text {
  align-self: center;
  justify-self: start;
}

@media (max-width: 768px){
  .webkit-iconList-text{
    text-align: left;
  }
}

.webkit-iconList-text{
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-iconList-text{
    font-size: 1.125rem;
  }
}

@media (max-width: 480px){
  .webkit-iconList-text{
    font-size: 1.125rem;
  }
}

.webkit-iconList-icon {
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

.webkit-iconList-heading {
  padding-bottom: 4.166666666666666%;
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-iconList-heading{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-iconList-heading{
  font-weight: 700;
  font-size: 1.75rem;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-iconList-heading{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-iconList-heading{
    font-size: 1.25rem;
  }
}

.webkit-iconList-heading::after {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  border-radius: 0.125rem;
  display: block;
  height: 0.25rem;
  width: 1.5rem;
  content: "";
}

.webkit-heading-underline {
  position: relative;
}

.webkit-heading-underline::after {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  height: 0.25rem;
  position: absolute;
  left: 0px;
  bottom: 1.75rem;
  width: 1.5rem;
  border-radius: 0.125rem;
  content: "";
}

.webkit-iconList-item-heading {
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;
}

@media (max-width: 768px){
  .webkit-iconList-item-heading{
    padding-top: 0px;
  }
}

.webkit-iconList-item-heading{
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-iconList-item-heading{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-iconList-item-heading{
    font-size: 1rem;
  }
}

.webkit-iconList-item-subtitle {
  font-weight: 400;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-iconList-item-subtitle{
    font-size: .75rem;
  }
}

@media (max-width: 480px){
  .webkit-iconList-item-subtitle{
    font-size: .75rem;
  }
}

.webkit-iconList-button-container {
  margin-top: 1.25rem;
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 993px){
  .webkit-iconList-button-container{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 768px){
  .webkit-iconList-button-container{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-iconList-button {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  margin-bottom: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-imageBanner {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
  padding-top: 37.5%;
}

@media (max-width: 1600px){
  .webkit-imageBanner{
    padding-top: 41.66666666666667%;
  }
}

@media (max-width: 993px){
  .webkit-imageBanner{
    padding-top: 56.25%;
  }
}

@media (max-width: 480px){
  .webkit-imageBanner{
    padding-top: 62.5%;
  }
}

.webkit-imageBanner-grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
}

.webkit-imageBanner-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.webkit-webcamBanner-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

@media (max-width: 768px){
  .webkit-webcamBanner{
    margin-bottom: 1rem;
    grid-column: span 2 / span 2;
  }
}

.webkit-webcamBanner-image {
  border-radius: 1rem;
  width: 100%;
}

@media (max-width: 768px){
  .webkit-webcamBanner-image{
    margin-bottom: 1rem;
  }
}

.webkit-webcamBanner-grid {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-webcamBanner-grid{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-webcamBanner-grid{
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 993px){
  .webkit-webcamBanner-grid{
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px){
  .webkit-webcamBanner-grid{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-webcamBanner-title {
  text-align: left;
  grid-column: span 3 / span 3;
  padding-bottom: 2.1rem;
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-webcamBanner-title{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-webcamBanner-title{
    font-size: 1.25rem;
  }
}

@media (max-width: 993px) {
  .webkit-webcamBanner-title {
    padding-bottom: 1.65rem;
  }
}

@media (max-width: 480px) {
  .webkit-webcamBanner-title {
    padding-bottom: 1.25rem;
  }
}

.webkit-webcamBanner-title::after {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  border-radius: 0.125rem;
  display: block;
  height: 0.25rem;
  width: 1.5rem;
  content: "";
}

.webkit-webcam-heading {
  text-align: left;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 768px){
  .webkit-webcam-heading{
    padding-top: 0.25rem;
  }
}

.webkit-webcam-heading{
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-webcam-heading{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-webcam-heading{
    font-size: 1rem;
  }
}

.webkit-quad-block-container {
  max-width: 1470px;
  display: grid;
}

@media (max-width: 1600px){
  .webkit-quad-block-container{
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (max-width: 768px){
  .webkit-quad-block-container{
    padding: 1.25rem;
  }
}

.webkit-quad-block-container{
  margin-left: auto;
  margin-right: auto;
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content max-content;
}

.webkit-quad-block-parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1280px){
  .webkit-quad-block-parent{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px){
  .webkit-quad-block-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-quad-block-parent * {
  min-width: 1px;
  min-height: 1px;
}

.webkit-quad-block {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-right: 0.5rem;
}

.webkit-quad-block:hover .webkit-btn__small {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-quard-item-box {
  justify-self: stretch;
}

.webkit-quad-block-title {
  font-weight: 700;
  font-size: 1rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

@media (max-width: 1280px){
  .webkit-quad-block-title{
    font-size: 1rem;
  }
}

@media (max-width: 993px){
  .webkit-quad-block-title{
    height: 100%;
  }
}

.webkit-quad-block-title{
  grid-column-start: 3;
}

@media (max-width: 1280px) {
  .webkit-quad-block-title {
    min-height: 60px;
  }
}

@media (max-width: 768px) {
  .webkit-quad-block-title {
    min-height: 100%;
  }
}

.webkit-quad-block-image {
  height: 250px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1280px) {
  .webkit-quad-block-image {
    height: 150px;
  }
}

.test {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  grid-gap: 25px;
  gap: 25px;
}

@media (max-width: 768px) {
  .test {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .test {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    gap: 10px;
  }
}

.agile__dots {
  top: 0 !important;
}

.agile__nav-button {
  display: none;
}

.agile__dot--current button {
  --tw-bg-opacity: 1;
  background-color: #323232 !important;
}

.dienstleistunhen-load-btn {
  padding-top: 0 !important;
  margin-bottom: 20px;
}

.webkit-quad-block-grid-text-container .fa-angle-right {
  border-radius: 50%;
  padding: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.webkit-quad-block-grid-text-container .fa-angle-right:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-slideshow-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: grid;
  position: relative;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-slideshow {
  overflow: hidden;
  grid-column: span 24 / span 24;
  grid-column-start: 1;
}

.webkit-slide-text-container {
  display: grid;
  align-self: center;
  position: absolute;
  top: 0px;
  width: 100%;
  padding-left: 16.666666666666664%;
  padding-right: 25%;
  grid-column-start: 3;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}

@media (min-width: 1601px){
  .webkit-slide-text-container{
    padding-left: 8.333333333333332%;
  }
}

@media (max-width: 1600px){
  .webkit-slide-text-container{
    padding-left: 8.333333333333332%;
  }
}

@media (max-width: 768px){
  .webkit-slide-text-container{
    padding-left: 8.333333333333332%;
    padding-right: 8.333333333333332%;
  }
}

.webkit-slide-text-container{
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content max-content;
  /* @apply py-10/24; */
  height: auto;
  top: 60%;
  bottom: auto;
  transform: translateY(-50%);
}

@media (max-width: 1600px) {
  .webkit-slide-text-container {
    top: 50%;
  }
}

.webkit-slide-button-container {
  padding-top: 4.166666666666666%;
  text-align: left;
}

.webkit-slide-button {
  display: inline-block;
}

.webkit-slide-image {
  padding-top: 33.33333333333333%;
}

@media (max-width: 1280px){
  .webkit-slide-image{
    padding-top: 56.25%;
  }
}

@media (max-width: 480px){
  .webkit-slide-image{
    padding-top: 100%;
  }
}

.webkit-slide-image.no-overlay::after {
  opacity: 0;
}

.webkit-slide-bg {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
}

.webkit-slide-heading {
  padding-bottom: 0.75rem;
}

@media (max-width: 993px){
  .webkit-slide-heading{
    padding-bottom: 1rem;
  }
}

.webkit-slide-heading{
  text-align: left;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: 700;
  font-size: 3.4375rem;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-slide-heading{
    font-size: 2.5rem;
  }
}

@media (max-width: 480px){
  .webkit-slide-heading{
    font-size: 1.875rem;
  }
}

.webkit-slide-heading{
  display: block !important;
}

@media (max-width: 480px) {
  .webkit-slide-heading {
    padding-bottom: 0.75rem;
    margin-left: 4.166666666666666%;
    margin-right: 4.166666666666666%;
  }
}

.webkit-slide-heading-single {
  align-self: flex-end;
  margin-bottom: 12.5%;
}

.webkit-slide-text {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: left;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: 400;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-weight: 100;
}

.slider-heading {
  font-weight: 700;
  margin-bottom: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-weight: 700;
  font-size: 3.4375rem;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .slider-heading{
    font-size: 2.5rem;
  }
}

@media (max-width: 480px){
  .slider-heading{
    font-size: 1.875rem;
  }
}

.slide {
  overflow: hidden;
  height: 100%;
  position: relative;
}

.agile__actions {
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
  bottom: 0;
}

.agile__dot {
  display: inline-block;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.agile__dot button {
  display: block;
  margin: 0;
  padding: 0;
  height: 12px;
  width: 12px;
  font-size: 0;
  border-radius: 50%;
  border: none;
  line-height: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

@media (max-width: 480px) {
  .agile__dot button {
    height: 10px;
    width: 10px;
  }
}

.agile__dot--current button {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
}

.agile__dots {
  position: relative;
  top: -2rem;
  display: inline-block !important;
}

@media (max-width: 480px) {
  .agile__dots {
    top: 0;
  }
}

.agile__slide .agile__nav-button {
  display: none !important;
}

.agile__slide .agile__dots {
  display: inline-block !important;
  height: 50px;
  width: 150px;
}

.webkit-slideshow-container .agile__nav-button {
  display: none;
}

.webkit-testimonial-outer-grid {
  margin-left: auto;
  margin-right: auto;
  padding-top: 4.166666666666666%;
  padding-bottom: 4.166666666666666%;
}

@media (max-width: 480px){
  .webkit-testimonial-outer-grid{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-testimonial-outer-grid{
  /*   @apply max-w-content; */
}

.webkit-testimonial-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.webkit-testimonial-grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-grid-box {
  grid-column-start: 1;
  min-height: 400px;
}

@media (max-width: 480px) {
  .webkit-grid-box {
    grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-rows: max-content max-content max-content;
  }
}

.webkit-grid-box {
  grid-column-start: 1;
}

.webkit-testimonial {
  display: flex;
}

.testimonial-btn-group {
  display: flex;
}

.webkit-testimonial-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@media (max-width: 1280px){
  .webkit-testimonial-title{
    margin-top: 1rem;
  }
}

.webkit-testimonial-title{
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-column-end: 25;
}

@media (max-width: 768px){
  .webkit-testimonial-title{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 480px){
  .webkit-testimonial-title{
    margin-bottom: 0.5rem;
    grid-column: span 22 / span 22;
    grid-column-start: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
}

.webkit-testimonial-title{
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  font-weight: 700;
  font-size: 1.75rem;
}

@media (max-width: 993px){
  .webkit-testimonial-title{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-testimonial-title{
    font-size: 1.25rem;
  }
}

.webkit-testimonial-title{
  line-height: 1.25;
}

.webkit-testimonial-text {
  margin-bottom: 1rem;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  grid-column: span 10 / span 10;
  grid-column-start: 8;
}

@media (max-width: 768px){
  .webkit-testimonial-text{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 480px){
  .webkit-testimonial-text{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }
}

.webkit-testimonial-text{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-weight: 400;
  font-size: 1rem;
}

.webkit-testimonial-button-container {
  display: flex;
}

@media (max-width: 1280px){
  .webkit-testimonial-button-container{
    flex-direction: column;
  }
}

.webkit-testimonial-button-container{
  grid-column: span 20 / span 20;
  grid-column-start: 3;
  grid-row: span 1 / span 1;
  grid-row-start: 6;
  justify-self: center;
  margin-top: 2.5rem;
}

@media (max-width: 768px){
  .webkit-testimonial-button-container{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 480px){
  .webkit-testimonial-button-container{
    justify-self: center;
    grid-column: span 24 / span 24;
    grid-column-start: 1;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}

.webkit-testimonial-image-aspect {
  border-radius: 1rem;
  padding-top: 0.5rem;
}

.webkit-testimonial-image {
  padding: 28%;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}

.webkit-grid-text-box {
  grid-column: span 10 / span 10;
  grid-column-start: 10;
}

@media (max-width: 1600px){
  .webkit-grid-text-box{
    grid-column-start: 13;
  }
}

@media (max-width: 1280px){
  .webkit-grid-text-box{
    grid-column-start: 13;
  }
}

@media (max-width: 768px){
  .webkit-grid-text-box{
    grid-column: span 18 / span 18;
    grid-column-start: 3;
  }
}

.webkit-grid-text-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.webkit-testimonial-image-container {
  height: 100%;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  z-index: 10;
  align-self: center;
  grid-column-start: 3;
  grid-column-end: 9;
}

@media (max-width: 1600px){
  .webkit-testimonial-image-container{
    grid-column-end: 13;
  }
}

@media (max-width: 1280px){
  .webkit-testimonial-image-container{
    grid-column-end: 12;
  }
}

@media (max-width: 993px){
  .webkit-testimonial-image-container{
    grid-column: span 9 / span 9;
    grid-column-start: 3;
  }
}

@media (max-width: 768px){
  .webkit-testimonial-image-container{
    grid-column: span 18 / span 18;
    grid-column-start: 3;
  }
}

@media (max-width: 480px) {
  .webkit-testimonial-image-container {
    height: 300px;
  }
}

.webkit-testimonial-outer-grid .agile__dots {
  position: flex;
}

@media (max-width: 480px) {
  .webkit-testimonial-outer-grid .agile__dots {
    top: -1rem;
  }
}

.webkit-testimonial-outer-grid .agile__nav-button {
  display: none;
}

.webkit-text-container {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(24, 1fr);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.webkit-text {
  text-align: left;
  grid-column-start: 5;
  grid-column-end: 22;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-text{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-text{
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .webkit-text {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}

.webkit-text-heading {
  grid-column-start: 3;
  grid-column-end: 23;
  text-align: left;
}

@media (max-width: 768px){
  .webkit-text-heading{
    grid-column-start: 3;
    grid-column-end: 23;
  }
}

.webkit-text-heading{
  font-weight: 700;
  font-size: 3.4375rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-text-heading{
    font-size: 2.5rem;
  }
}

@media (max-width: 480px){
  .webkit-text-heading{
    font-size: 1.875rem;
  }
}

@media (max-width: 993px) {
  .webkit-text-heading {
    padding-bottom: 0.9rem;
  }
}

@media (max-width: 480px) {
  .webkit-text-heading {
    padding-bottom: 0.8rem;
  }
}

.webkit-text-cell {
  grid-column: span 18 / span 18;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-text-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-textGrid-text, .webkit-textGrid-heading {
  justify-content: flex-start;
  text-align: left;
}

.webkit-textGrid-background {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  position: relative;
  z-index: 10;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-textGrid-background{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-textGrid-background{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-textGrid-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  /*   @apply max-w-content mx-auto; */
}

.webkit-textGrid-container-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

.webkit-textGrid-parent {
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  gap: 1.5rem;
}

@media (max-width: 993px){
  .webkit-textGrid-parent{
    gap: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-textGrid-parent{
    gap: 0.75rem;
  }
}

.webkit-textGrid-parent{
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1600px){
  .webkit-textGrid-parent{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1280px){
  .webkit-textGrid-parent{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 993px){
  .webkit-textGrid-parent{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px){
  .webkit-textGrid-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px){
  .webkit-textGrid-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-textGrid-item {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  /*  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  padding-top: 8.333333333333332%;
}

@media (max-width: 1600px) {
  .webkit-textGrid-item:nth-child(4n) {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  }
}

@media (max-width: 768px) {
  .webkit-textGrid-item:nth-child(2n) {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  }
}

@media (min-width: 1280px) {
  .webkit-textGrid-item:nth-child(4n) {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  }

  .webkit-textGrid-item:nth-child(5n), .webkit-textGrid-item:first-child {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  }
}

.webkit-textGrid-item-grid {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: -webkit-max-content 1fr -webkit-max-content;
  grid-template-rows: max-content 1fr max-content;
}

.webkit-textGrid-item-grid > * {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

.webkit-textGrid-cell-background {
  grid-row: span 3 / span 3;
  grid-row-start: 1;
  background-color: transparent;
}

.webkit-textGrid-heading {
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  padding-bottom: 0.5rem;
}

@media (max-width: 480px){
  .webkit-textGrid-heading{
    padding-bottom: 0.75rem;
    word-break: break-all;
  }
}

.webkit-textGrid-heading{
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-textGrid-heading{
    font-size: 1.125rem;
  }
}

@media (max-width: 480px){
  .webkit-textGrid-heading{
    font-size: 1.125rem;
  }
}

.webkit-textGrid-text {
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  padding-bottom: 1rem;
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-textGrid-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-textGrid-text{
    font-size: 1rem;
  }
}

.webkit-textGrid-button-cell {
  grid-row: span 1 / span 1;
  grid-row-start: 3;
}

.webkit-textGrid-button {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  margin-right: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 480px){
  .webkit-textGrid-button{
    margin-right: 0px;
  }
}

.webkit-textGrid-load-more-button-container {
  display: flex;
  align-content: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.webkit-textGrid-item {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 400ms;
  /*   @screen minL {
      &:hover {
        @apply theme('textGrid.hover.itemBackgroundColor');

        .webkit-textGrid-heading {
          @apply theme('textGrid.hover.heading');
        }

        .webkit-textGrid-text {
          @apply theme('textGrid.hover.text');
        }

        i {
          @apply theme('textGrid.hover.button');
        }
      }
    } */
}

.webkit-textGrid-load-more-button-container-btn-left {
  display: flex;
  justify-content: left;
  padding: 0;
  margin: 30px 0;
}

.trier-grid-heading {
  font-weight: 700;
  margin-bottom: 1rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.webkit-text-grid-date:hover {
  background-color: transparent;
  color: black;
}

.webkit-video-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.webkit-video {
  display: grid;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  grid-template-columns: repeat(24, 1fr);
}

@media (max-width: 1280px){
  .webkit-video{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-video{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-video-cell {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
}

.webkit-video-aspect {
  height: 100%;
  padding-top: 56.25%;
  position: relative;
  display: none;
}

.webkit-video-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.webkit-video-heading {
  /* @apply text-f2 text-color1 bg-color3; */
  margin-top: 12rem;
}

@media (max-width: 993px){
  .webkit-video-heading{
    padding-bottom: 1rem;
  }
}

.webkit-video-heading{
  text-align: left;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-video-heading{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-video-heading{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .webkit-video-heading {
    padding-bottom: 0.75rem;
    margin-left: 4.166666666666666%;
    margin-right: 4.166666666666666%;
  }
}

.webkit-video-iframe {
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.video-overlay {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.video-overlay .red-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /*   @apply h-14 w-14 m:h-10 m:w-10 xs:h-9 xs:w-9; */
  margin-bottom: 0.75rem;
  /*  border-width: 4px; */
}

.video-overlay .red-button-icon i {
  font-size: 3.4375rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

.webkit-gallery-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-gallery-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-gallery-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-gallery-title {
  text-align: left;
  grid-column: span 20 / span 20;
  grid-column-start: 3;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  padding-bottom: 2.1rem;
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-gallery-title{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-gallery-title{
    font-size: 1.25rem;
  }
}

@media (max-width: 993px) {
  .webkit-gallery-title {
    padding-bottom: 1.65rem;
  }
}

@media (max-width: 480px) {
  .webkit-gallery-title {
    padding-bottom: 1.25rem;
  }
}

.webkit-gallery-title::after {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  border-radius: 0.125rem;
  display: block;
  height: 0.25rem;
  width: 1.5rem;
  content: "";
}

.webkit-gallery-container-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
}

.webkit-gallery-parent {
  border-radius: 1rem;
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  gap: 1.5rem;
}

@media (max-width: 480px){
  .webkit-gallery-parent{
    gap: 0.75rem;
  }
}

.webkit-gallery-parent{
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1600px){
  .webkit-gallery-parent{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1280px){
  .webkit-gallery-parent{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 993px){
  .webkit-gallery-parent{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px){
  .webkit-gallery-parent{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px){
  .webkit-gallery-parent{
    grid-template-columns: repeat(2, 1fr);
  }
}

.webkit-gallery-item {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
}

.webkit-gallery-item-grid {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: -webkit-max-content 1fr;
  grid-template-rows: max-content 1fr;
}

.webkit-gallery-item-grid > * {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

.webkit-gallery-cell-background {
  grid-row: span 2 / span 2;
  grid-row-start: 1;
  background-color: transparent;
}

.webkit-gallery-cell-overlay {
  position: relative;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  border-radius: 1rem;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.25;
  z-index: 666;
}

.webkit-gallery-cell-overlay-container {
  position: absolute;
  text-align: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

.webkit-gallery-cell-overlay-icon {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 993px){
  .webkit-gallery-cell-overlay-icon{
    height: 2rem;
    width: 2rem;
  }
}

@media (max-width: 480px){
  .webkit-gallery-cell-overlay-icon{
    height: 1.5rem;
    width: 1.5rem;
  }
}

.webkit-gallery-cell-overlay-icon{
  margin-bottom: 0.75rem;
  border-width: 4px;
}

.webkit-gallery-cell-overlay-icon i {
  font-weight: 500;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (min-width: 994px) {
  .webkit-gallery-item:hover .webkit-gallery-cell-overlay {
    opacity: 1;
  }
}

.webkit-gallery-cell-overlay-text {
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-gallery-cell-overlay-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-gallery-cell-overlay-text{
    font-size: 1rem;
  }
}

.webkit-gallery-cell-img {
  border-radius: 1rem;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  overflow: hidden;
}

.webkit-gallery-image {
  border-radius: 1rem;
  width: 100%;
  padding-top: 100%;
}

.webkit-gallery-heading {
  text-align: left;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-gallery-heading{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-gallery-heading{
    font-size: 1rem;
  }
}

.webkit-gallery-load-more-button-container {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-top: 1.5rem;
}

@media (max-width: 480px){
  .webkit-gallery-load-more-button-container{
    padding-top: 1.25rem;
  }
}

.webkit-gallery-load-less-button-container {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding: 1rem;
  padding-top: 1.5rem;
}

@media (max-width: 480px){
  .webkit-gallery-load-less-button-container{
    padding-top: 1.25rem;
  }
}

.info-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
}

@media (max-width: 993px){
  .info-container{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .info-container{
    font-size: 1rem;
  }
}

.info-container{
  margin-top: calc(-4.1666% + -90px);
}

.webkit-intro-search-container {
  align-self: center;
  justify-content: flex-start;
  position: relative;
  text-align: center;
  grid-column: span 20 / span 20;
  grid-column-start: 3;
  margin-top: 2rem;
  width: 100%;
  display: flex;
  z-index: 2;
}

@media (max-width: 993px) {
  .webkit-intro-search-container {
    border-radius: 1rem;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .webkit-intro-search-container {
    grid-column: span 22 / span 22;
    grid-column-start: 2;
    flex-wrap: wrap;
  }
}

.webkit-intro-webcam {
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  flex-basis: 250px;
  flex-grow: 0;
  flex-shrink: 0;
  order: 1;
}

@media (max-width: 993px) {
  .webkit-intro-webcam {
    flex-basis: 50%;
    height: 50%;
    order: 2;
  }
}

.webkit-intro-webcam-label {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  position: absolute;
  top: 40px;
  left: 30px;
}

@media (max-width: 993px) {
  .webkit-intro-webcam-label {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.webkit-intro-search-label {
  left: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  position: absolute;
  top: 40px;
  /* left: 30px; */
}

@media (max-width: 993px) {
  .webkit-intro-search-label {
    top: 15px;
  }
}

@media (max-width: 768px) {
  .webkit-intro-search-label {
    left: 0.75rem;
    top: 10px;
  }
}

.webkit-intro-search {
  position: relative;
  flex-grow: 6;
  display: flex;
  justify-content: start;
  align-items: center;
  order: 1;
  z-index: 1;
}

@media (max-width: 993px) {
  .webkit-intro-search {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    height: 50%;
    order: 1;
  }

  .webkit-intro-search .search-menu-container {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .webkit-intro-search .webkit-intro-search-label {
    font-weight: 400;
    font-size: 1rem;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    text-align: left;
  }
}

.webkit-intro-search form {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 1rem;
  width: calc(100% - 60px);
  position: relative;
  height: 56px;
  width: 100%;
}

@media (max-width: 768px) {
  .webkit-intro-search form {
    width: calc(100% - 30px);
    height: 36px;
  }
}

.search-input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 1rem;
  float: left;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 0.75rem;
  padding-right: 0px;
  width: calc(100% - 206px);
  height: 56px;
  font-weight: 400;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 768px) {
  .search-input {
    height: 36px;
    width: calc(100% - 216px);
  }
}

@media (max-width: 480px) {
  .search-input {
    width: calc(100% - 166px);
  }
}

.suggestion-select {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  width: 150px;
  display: inline-block;
  float: left;
  cursor: pointer;
}

.suggestion-select .bone-select-input {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
}

@media (max-width: 480px) {
  .suggestion-select {
    width: 130px;
  }
}

.search-menu-placeholder {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  height: 56px;
  width: 100%;
  position: relative;
}

@media (max-width: 768px) {
  .search-menu-placeholder {
    height: 36px;
  }
}

.search-menu-placeholder-text {
  font-size: 1.5rem;
}

@media (max-width: 993px){
  .search-menu-placeholder-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .search-menu-placeholder-text{
    font-size: 1rem;
  }
}

.search-menu-placeholder-text{
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search-menu-entries {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  visibility: hidden;
  height: 0;
  transition: height 2s linear;
}

.search-menu-entries.open {
  font-size: 1rem;
  visibility: visible;
  height: auto;
}

.search-menu-entry:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.search-menu-entry{
  display: block;
  padding: 0.75rem;
}

.search-menu-entry:hover{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-intro-fahrplan {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 3;
}

@media (max-width: 993px) {
  .webkit-intro-fahrplan {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    flex-basis: 50%;
    height: 50%;
    float: left;
  }
}

@media (max-width: 768px) {
  .webkit-intro-fahrplan {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

.info-prefooter {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: -1px;
  z-index: 10;
}

.info-prefooter .info-container {
  margin-top: calc(15vw - 100px);
  position: relative;
  bottom: calc(15vw - 150px);
  height: 400px;
}

@media (max-width: 1280px) {
  .info-prefooter .info-container {
    margin-top: 60px;
    bottom: 30px;
    height: 380px;
  }
}

@media (max-width: 993px) {
  .info-prefooter .info-container {
    margin-top: 160px;
    bottom: 130px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .info-prefooter .info-container {
    margin-top: 160px;
    bottom: 130px;
    height: 400px;
  }
}

@media (max-width: 480px) {
  .info-prefooter .info-container {
    margin-top: 160px;
    bottom: 130px;
    height: 250px;
  }
}

.info-prefooter .bone-select-input {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.info-prefooter .webkit-intro-search-container {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.info-prefooter .webkit-intro-search-label {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.info-prefooter .bone-select-label {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.info-prefooter .suggestion-select {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.info-prefooter .search-menu-entries {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  font-size: .7rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  position: absolute;
  bottom: 100%;
  width: 100%;
}

.info-prefooter .search-menu-entry:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.info-prefooter .search-menu-container {
  position: relative;
}

.prefooter-img {
  position: absolute;
  bottom: -1px;
  z-index: 3;
  min-height: 100px;
  max-width: none;
  min-width: 100%;
}

a.fahrplan-btn {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  border-radius: 0.125rem;
  line-height: 21px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media (max-width: 768px) {
  a.fahrplan-btn {
    font-size: .75rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

i.cablecar {
  width: 21px;
  height: 21px;
  background-image: url("./assets/icons/cablecar.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

@media (max-width: 768px) {
  i.cablecar {
    width: 14px;
    height: 14px;
  }
}

.btn-search {
  width: 56px;
  height: 56px;
}

@media (max-width: 768px) {
  .btn-search {
    height: 18px;
    width: 18px;
  }
}

.webkit-text-infos-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-text-infos-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-text-infos-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-text-infos-text {
  margin-bottom: 2rem;
  text-align: left;
  grid-column: span 13 / span 13;
  grid-column-start: 3;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-text-infos-text{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-text-infos-text{
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .webkit-text-infos-text {
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-infos {
  text-align: left;
  grid-column: span 6 / span 6;
  grid-column-start: 17;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-infos{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-infos{
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .webkit-infos {
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-infos-website {
  font-weight: 700;
  margin-top: 1.5rem;
}

.webkit-infos-socialmedia {
  margin-top: 1.5rem;
}

.webkit-infos-social-media-icons {
  position: relative;
  height: 1.75rem;
  margin-left: 0px;
  margin-right: 0px;
  width: 1.75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 0.125rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-infos-social-media-icons::before {
  margin-top: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.webkit-structureOverview-text, .webkit-structureOverview-title, .webkit-structureOverview-heading, .webkit-structureOverview-item1 .webkit-structureOverview-heading {
  text-align: left;
}

.webkit-structureOverview-background {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-structureOverview-background{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-structureOverview-background{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-structureOverview-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-structureOverview-container-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-structureOverview-container-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-structureOverview-parent {
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  grid-template-columns: repeat(6, 1fr);
}

@media (max-width: 768px){
  .webkit-structureOverview-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-structureOverview-parent{
  gap: 2rem;
}

@media (max-width: 993px){
  .webkit-structureOverview-parent{
    gap: 1rem;
  }
}

.webkit-structureOverview-item23 {
  grid-column: span 2 / span 2;
  grid-row: span 1 / span 1;
}

@media (max-width: 768px){
  .webkit-structureOverview-item23{
    grid-column: span 3 / span 3;
    grid-row: span 1 / span 1;
  }
}

.webkit-structureOverview-item23{
  position: relative;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
}

.webkit-structureOverview-item23:first-child, .webkit-structureOverview-item23:nth-child(2) {
  grid-column: span 3 / span 3;
  grid-row: span 1 / span 1;
}

.webkit-structureOverview-item1 {
  grid-column: span 6 / span 6;
  grid-row: span 1 / span 1;
}

@media (max-width: 768px){
  .webkit-structureOverview-item1{
    grid-column: span 3 / span 3;
    grid-row: span 1 / span 1;
  }
}

.webkit-structureOverview-item1{
  padding: 1rem;
}

@media (max-width: 768px){
  .webkit-structureOverview-item1{
    padding: 0px;
  }
}

.webkit-structureOverview-item1{
  position: relative;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
}

.webkit-structureOverview-item1 .webkit-structureOverview-item-grid {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: 33% auto;
}

@media (max-width: 768px){
  .webkit-structureOverview-item1 .webkit-structureOverview-item-grid{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-structureOverview-item1 .webkit-structureOverview-item-grid > * {
  grid-row: span 1 / span 1;
  grid-row-start: 1;
}

.webkit-structureOverview-item1 .webkit-structureOverview-cell-img {
  margin-bottom: 1rem;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  grid-column: span 1 / span 1;
  grid-column-start: 1;
  overflow: hidden;
  margin-bottom: 0px;
}

.webkit-structureOverview-item1 .webkit-structureOverview-image {
  background-size: cover;
  height: 100%;
  width: 100%;
  padding-top: 62.5%;
}

@media (max-width: 993px){
  .webkit-structureOverview-item1 .webkit-structureOverview-image{
    padding-top: 75%;
  }
}

@media (max-width: 768px){
  .webkit-structureOverview-item1 .webkit-structureOverview-image{
    padding-top: 56.25%;
  }
}

.webkit-structureOverview-item1 .webkit-structureOverview-heading {
  margin-bottom: 1rem;
  grid-column: span 1 / span 1;
  grid-column-start: 2;
  margin-bottom: 1rem;
}

@media (max-width: 768px){
  .webkit-structureOverview-item1 .webkit-structureOverview-heading{
    grid-column-start: 1;
    grid-row: span 1 / span 1;
    grid-row-start: 2;
  }
}

.webkit-structureOverview-item1 .webkit-structureOverview-heading{
  font-weight: 700;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-structureOverview-item1 .webkit-structureOverview-heading{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-structureOverview-item1 .webkit-structureOverview-heading{
    font-size: 1rem;
  }
}

@media (max-width: 768px){
  .webkit-structureOverview-item1 .webkit-structureOverview-heading{
    margin-top: 1rem;
  }
}

.webkit-structureOverview-item1 .webkit-structureOverview-heading{
  padding-left: 25px;
  padding-right: 25px;
}

.webkit-structureOverview-item1 .webkit-structureOverview-description {
  grid-column: span 1 / span 1;
  grid-column-start: 2;
}

@media (max-width: 768px){
  .webkit-structureOverview-item1 .webkit-structureOverview-description{
    grid-column-start: 1;
    grid-row: span 1 / span 1;
    grid-row-start: 3;
  }
}

.webkit-structureOverview-item1 .webkit-structureOverview-description{
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-structureOverview-item1 .webkit-structureOverview-description{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-structureOverview-item1 .webkit-structureOverview-description{
    font-size: 1rem;
  }
}

.webkit-structureOverview-item1 .webkit-structureOverview-description{
  margin-top: 2rem;
  padding-bottom: 1rem;
}

@media (max-width: 768px){
  .webkit-structureOverview-item1 .webkit-structureOverview-description{
    margin-top: 0px;
  }
}

.webkit-structureOverview-item1 .webkit-structureOverview-description{
  padding-left: 25px;
  padding-right: 25px;
}

.webkit-structureOverview-item1 .webkit-structureOverview-button-cell {
  grid-column-start: 2;
  grid-row: span 1 / span 1;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
}

.webkit-structureOverview-item1 .webkit-structureOverview-button {
  position: absolute;
  bottom: 0px;
  left: calc(33% + 32px);
}

.webkit-structureOverview-item2 {
  grid-column: span 3 / span 3;
  grid-row: span 1 / span 1;
}

@media (max-width: 768px){
  .webkit-structureOverview-item2{
    grid-column: span 6 / span 6;
    grid-row: span 1 / span 1;
  }
}

.webkit-structureOverview-item2{
  position: relative;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
}

.webkit-structureOverview-item3 {
  grid-column: span 2 / span 2;
  grid-row: span 1 / span 1;
}

@media (max-width: 768px){
  .webkit-structureOverview-item3{
    grid-column: span 3 / span 3;
    grid-row: span 1 / span 1;
  }
}

.webkit-structureOverview-item3{
  position: relative;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
}

.webkit-structureOverview-item-grid {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
}

.webkit-structureOverview-item-grid > * {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

.webkit-structureOverview-cell-background {
  grid-row: span 5 / span 5;
  grid-row-start: 1;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 0px;
}

.webkit-structureOverview-cell-img {
  margin-bottom: 1rem;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  overflow: hidden;
}

.webkit-structureOverview-image {
  width: 100%;
  padding-top: 62.5%;
}

@media (max-width: 993px){
  .webkit-structureOverview-image{
    padding-top: 75%;
  }
}

@media (max-width: 768px){
  .webkit-structureOverview-image{
    padding-top: 56.25%;
  }
}

.webkit-structureOverview-heading {
  margin-bottom: 1rem;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  font-weight: 700;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-structureOverview-heading{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-structureOverview-heading{
    font-size: 1rem;
  }
}

.webkit-structureOverview-heading{
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
}

.webkit-structureOverview-description {
  grid-row: span 1 / span 1;
  grid-row-start: 3;
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-structureOverview-description{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-structureOverview-description{
    font-size: 1rem;
  }
}

.webkit-structureOverview-description{
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
  padding-bottom: 1rem;
}

.webkit-structureOverview-title {
  grid-row: span 1 / span 1;
  grid-row-start: 3;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
  padding-top: 0.25rem;
  padding-bottom: 0.75rem;
}

@media (max-width: 480px){
  .webkit-structureOverview-title{
    padding-bottom: 0.5rem;
  }
}

.webkit-structureOverview-text {
  grid-row: span 1 / span 1;
  grid-row-start: 4;
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-structureOverview-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-structureOverview-text{
    font-size: 1rem;
  }
}

.webkit-structureOverview-text{
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
}

.webkit-structureOverview-button-cell {
  grid-row: span 1 / span 1;
  grid-row-start: 5;
}

.webkit-structureOverview-button {
  display: flex;
  align-self: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  justify-content: flex-end;
}

.custom-map-container {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.custom-map-background {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  width: 100%;
  margin-bottom: -2px;
}

.mapContainer {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
  width: 100%;
  height: 400px;
}

.suche-grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.suche-container {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

.suche-title {
  text-align: left;
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .suche-title{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.suche-title{
  padding-bottom: 0.5rem;
  padding-top: 2rem;
  font-weight: 700;
  font-size: 3.4375rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .suche-title{
    font-size: 2.5rem;
  }
}

@media (max-width: 480px){
  .suche-title{
    font-size: 1.875rem;
  }
}

@media (max-width: 993px) {
  .suche-title {
    padding-bottom: 1.65rem;
  }
}

@media (max-width: 480px) {
  .suche-title {
    padding-bottom: 1.25rem;
  }
}

.suche-description {
  padding-bottom: 2rem;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .suche-description{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .suche-description{
    font-size: 1rem;
  }
}

.search-result {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  --tw-border-opacity: 1;
  border-color: rgba(141, 23, 47, var(--tw-border-opacity));
}

.search-result:hover{
  --tw-border-opacity: 1;
  border-color: rgba(141, 23, 47, var(--tw-border-opacity));
}

.search-result{
  border-bottom-width: 1px;
  display: grid;
  padding: 0.75rem;
  grid-template-columns: 113px auto;
}

@media (max-width: 768px) {
  .search-result {
    grid-template-columns: 100px auto;
  }
}

.search-result:hover .search-result-title {
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.search-result:hover .search-result-type {
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.search-results {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.search-results-title {
  --tw-border-opacity: 1;
  border-color: rgba(141, 23, 47, var(--tw-border-opacity));
  border-bottom-width: 1px;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .search-results-title{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .search-results-title{
    font-size: 1rem;
  }
}

.search-results-title{
  padding-bottom: 1rem;
}

.search-result-text {
  padding: 1rem;
  padding-left: 1.25rem;
}

.search-result-title {
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .search-result-title{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .search-result-title{
    font-size: 1rem;
  }
}

.search-result-type {
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .search-result-type{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .search-result-type{
    font-size: 1rem;
  }
}

.search-result-image {
  background-size: cover;
  height: 100%;
  width: 100%;
  background-position: center;
}

.search-form {
  margin-bottom: 5rem;
  position: relative;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-bottom-width: 1px;
  height: 3rem;
  width: 100%;
}

.search-form .search-button {
  margin-top: 0.75rem;
  position: relative;
  width: 20px;
  height: 22px;
}

.search-form .search-button i {
  position: absolute;
  top: 0;
  right: 0;
}

.search-form .search-input {
  height: 2.5rem;
  padding: 0.75rem;
  width: calc(100% - 30px);
}

.download-grid-container {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
}

.download-grid-cell {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1280px){
  .download-grid-cell{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px){
  .download-grid-cell{
    grid-template-columns: repeat(1, 1fr);
  }
}

.download-grid-button {
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

.download-grid-text-cell {
  display: flex;
  height: 2.5rem;
  width: auto;
  align-self: center;
  grid-column: span 1 / span 1;
  grid-column-start: 2;
}

.download-grid-text {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-right: 1rem;
  white-space: nowrap;
}

.download-inner-grid {
  display: grid;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
}

.download-grid-content {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 1rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (min-width: 994px) {
  .download-grid-content:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .download-grid-content i:hover{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .download-grid-content a:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-veranstaltungen-text, .webkit-veranstaltungen-heading {
  justify-content: flex-start;
  text-align: left;
}

.webkit-veranstaltungen-background {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  position: relative;
  z-index: 10;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1280px){
  .webkit-veranstaltungen-background{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-veranstaltungen-background{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-veranstaltungen-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.webkit-veranstaltungen-container-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 1600px){
  .webkit-veranstaltungen-container-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 768px){
  .webkit-veranstaltungen-container-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 480px){
  .webkit-veranstaltungen-container-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-veranstaltungen-parent {
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  gap: 1.5rem;
}

@media (max-width: 993px){
  .webkit-veranstaltungen-parent{
    gap: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-veranstaltungen-parent{
    gap: 0.75rem;
  }
}

.webkit-veranstaltungen-parent{
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1600px){
  .webkit-veranstaltungen-parent{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1280px){
  .webkit-veranstaltungen-parent{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 993px){
  .webkit-veranstaltungen-parent{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px){
  .webkit-veranstaltungen-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px){
  .webkit-veranstaltungen-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-veranstaltungen-item {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.webkit-veranstaltungen-item:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.webkit-veranstaltungen-item{
  border-radius: 1rem;
  /*  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
}

@media (max-width: 1600px) {
  .webkit-veranstaltungen-item:nth-child(4n) {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  }
}

@media (max-width: 768px) {
  .webkit-veranstaltungen-item:nth-child(2n) {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  }
}

@media (min-width: 1280px) {
  .webkit-veranstaltungen-item:nth-child(4n) {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  }

  .webkit-veranstaltungen-item:nth-child(5n), .webkit-veranstaltungen-item:first-child {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  }
}

.webkit-veranstaltungen-item-grid {
  height: 100%;
  overflow: hidden;
}

.webkit-veranstaltungen-cell-background {
  grid-row: span 3 / span 3;
  grid-row-start: 1;
  background-color: transparent;
}

.webkit-veranstaltungen-heading {
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

@media (max-width: 993px){
  .webkit-veranstaltungen-heading{
    padding-left: 2.5rem;
  }
}

@media (max-width: 480px){
  .webkit-veranstaltungen-heading{
    padding-bottom: 0.75rem;
    word-break: break-all;
  }
}

.webkit-veranstaltungen-heading{
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-veranstaltungen-heading{
    font-size: 1.125rem;
  }
}

@media (max-width: 480px){
  .webkit-veranstaltungen-heading{
    font-size: 1.125rem;
  }
}

.webkit-veranstaltungen-main-heading {
  align-self: flex-start;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  width: 100%;
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 1600px){
  .webkit-veranstaltungen-main-heading{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 768px){
  .webkit-veranstaltungen-main-heading{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 480px){
  .webkit-veranstaltungen-main-heading{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-veranstaltungen-main-heading{
  padding-bottom: 2.1rem;
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-veranstaltungen-main-heading{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-veranstaltungen-main-heading{
    font-size: 1.25rem;
  }
}

@media (max-width: 993px) {
  .webkit-veranstaltungen-main-heading {
    padding-bottom: 1.65rem;
  }
}

@media (max-width: 480px) {
  .webkit-veranstaltungen-main-heading {
    padding-bottom: 1.25rem;
  }
}

.webkit-veranstaltungen-main-heading::after {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  border-radius: 0.125rem;
  display: block;
  height: 0.25rem;
  width: 1.5rem;
  content: "";
}

.webkit-veranstaltungen-text {
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
}

@media (max-width: 993px){
  .webkit-veranstaltungen-text{
    padding-left: 2.5rem;
  }
}

.webkit-veranstaltungen-text{
  font-weight: 400;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-veranstaltungen-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-veranstaltungen-text{
    font-size: 1rem;
  }
}

.webkit-veranstaltungen-date {
  padding: 0.75rem;
  position: absolute;
  top: 0px;
  left: 0px;
  --tw-border-opacity: 1;
  border-color: rgba(141, 23, 47, var(--tw-border-opacity));
  border-radius: 0.75rem;
  border-width: 2px;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.webkit-veranstaltungen-datum {
  font-size: 1.5rem;
  padding-left: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-veranstaltungen-datum{
    padding-left: 2.5rem;
  }
}

.webkit-veranstaltungen-button-cell {
  grid-row: span 1 / span 1;
  grid-row-start: 3;
}

.webkit-veranstaltungen-button {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  margin-right: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 480px){
  .webkit-veranstaltungen-button{
    margin-right: 1rem;
  }
}

.webkit-veranstaltungen-load-more-button-container {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 1600px){
  .webkit-veranstaltungen-load-more-button-container{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 768px){
  .webkit-veranstaltungen-load-more-button-container{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 480px){
  .webkit-veranstaltungen-load-more-button-container{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-veranstaltungen-load-more-button-container{
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding-top: 2rem;
}

@media (max-width: 480px){
  .webkit-veranstaltungen-load-more-button-container{
    padding-top: 1rem;
    text-align: center;
  }
}

.webkit-veranstaltungen-load-more-button-container{
  grid-row-start: 3;
}

.webkit-veranstaltungen-item {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 400ms;
}

@media (min-width: 994px) {
  .webkit-veranstaltungen-item:hover i {
    background-color: transparent;
  }
}

.webkit-form-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: left;
}

.webkit-form {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .webkit-form{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-form-title {
  font-weight: 700;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-form-title{
    font-size: .1.5625rem;
  }
}

@media (max-width: 480px){
  .webkit-form-title{
    font-size: 1.25rem;
  }
}

.webkit-form-title{
  padding-bottom: 2rem;
}

@media (max-width: 993px) {
  .webkit-form-title {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 480px) {
  .webkit-form-title {
    padding-bottom: 1.5rem;
  }
}

.webkit-form-title::after {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  border-radius: 0.125rem;
  display: block;
  height: 0.25rem;
  width: 1.5rem;
  content: "";
}

.form-grid {
  display: grid;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
  row-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 1.5rem;
}

.schulungen-form-input > label {
  display: block;
  font-weight: 700;
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
}

.schulungen-form-input > input {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}

.schulungen-form-input > textarea {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  padding: 1rem;
  width: 100%;
}

.upload-button {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  font-size: .7rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 994px) {
  .upload-button:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .upload-button i:hover{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
}

.upload-icon {
  padding-right: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

.data-loaded {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-header-placeholder, .webkit-header {
  display: grid;
  top: 0px;
  width: 100%;
  grid-template-columns: repeat(24, 1fr);
  z-index: 9999;
  height: 3rem;
}

.webkit-header {
  position: fixed;
  height: 0 !important;
}

.webkit-header-cell {
  grid-column-start: 1;
  grid-column-end: 25;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 10px 2px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-header-inner-grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-header-logo {
  align-items: center;
  align-self: center;
  justify-self: start;
  grid-column: span 7 / span 7;
  grid-column-start: 1;
}

@media (max-width: 993px){
  .webkit-header-logo{
    grid-column: span 4 / span 4;
    grid-column-start: 4;
  }
}

.webkit-header-logo{
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-column-start: 2;
  grid-column: span 22/span 22;
  grid-column-start: 2 !important;
}

.webkit-header-logo-image {
  height: 2.5rem;
  height: 100%;
  width: auto;
  margin-right: 0.5rem;
  fill: currentColor;
}

.webkit-navi {
  padding: 0.75rem;
  margin-left: 0.5rem;
}

.webkit-navi-links {
  font-weight: 700;
  padding-right: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

.webkit-navi-links a:hover {
  border-top: 3px solid #867A67;
}

.active-page-header {
  border-top: 3px solid #867A67;
}

.webkit-header-logo2-image {
  display: flex;
  position: relative;
  top: 0.5rem;
  left: 1.25rem;
}

@media (max-width: 993px){
  .webkit-header-logo2-image{
    display: none;
  }
}

@media (max-width: 768px){
  .webkit-header-logo2-image{
    left: 0.5rem;
    top: 0.75rem;
  }
}

.webkit-header-logo2-image{
  width: 45px;
  height: 45px;
}

@media (max-width: 768px) {
  .webkit-header-logo2-image {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 993px) {
  .webkit-header-logo2-image {
    margin: 10px;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .webkit-header-logo2-image {
    padding: 0 3px;
    margin-top: 0;
    margin-left: 5px;
  }
}

.webkit-header-button {
  justify-self: end;
  text-align: center;
  grid-column: span 2 / span 2;
  grid-column-start: 22;
  grid-column: span 2 / span 2;
  grid-column-start: 20;
}

@media (max-width: 1600px){
  .webkit-header-button{
    grid-column: span 3 / span 3;
    grid-column-start: 21;
  }
}

@media (max-width: 993px){
  .webkit-header-button{
    grid-column: span 4 / span 4;
    grid-column-start: 19;
  }
}

@media (max-width: 480px){
  .webkit-header-button{
    grid-column: span 6 / span 6;
    grid-column-start: 15;
  }
}

.webkit-header-navigation-container {
  display: none;
}

@media (min-width: 0px) {
  .webkit-header-navigation-container {
    display: block;
    justify-self: end;
    grid-column-start: 8;
    grid-column-end: 24;
  }

  @media (max-width: 768px){
    .webkit-header-navigation-container{
      grid-column-start: 13;
      grid-column-end: 24;
    }
  }

  @media (max-width: 480px) {
    .webkit-header-navigation-container {
      height: 100%;
    }
  }
}

.webkit-menu-icon {
  cursor: pointer;
  display: inline-block;
  height: 3rem;
  height: 100%;
  z-index: 50;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 480px) {
  .webkit-menu-icon {
    width: 65px !important;
  }
}

.webkit-header-contact {
  display: flex;
  padding: 1rem;
}

@media (max-width: 480px){
  .webkit-header-contact{
    padding: 0.5rem;
  }
}

.webkit-header-contact{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.webkit-header-contact:hover {
  color: black;
}

@media (min-width: 994px) {
  .webkit-header-button:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-header-navigation-link {
  display: inline-block;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  cursor: pointer;
}

.webkit-header-navigation-link:after {
  content: "";
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  opacity: 0;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-bottom-width: 1px;
}

@media (min-width: 994px) {
  .webkit-header-navigation-link:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .webkit-header-navigation-link:hover:after {
    opacity: 1;
  }
}

.webkit-header-navigation-text {
  display: inline-block;
  font-weight: 700;
  font-size: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-header-navigation-text:after {
  content: "";
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  opacity: 0;
  display: block;
  width: 100%;
}

.webkit-language-switcher-container {
  display: none;
}

.webkit-language-switcher {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-weight: 700;
  font-size: 1.5rem;
}

@media (max-width: 993px){
  .webkit-language-switcher{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-language-switcher{
    font-size: 1rem;
  }
}

@media (min-width: 0px) {
  .webkit-language-switcher {
    font-size: .65rem;
    --tw-text-opacity: 1;
    color: rgba(141, 23, 47, var(--tw-text-opacity));
  }
}

.webkit-language-switcher ul li {
  display: inline-block;
  vertical-align: top;
}

.webkit-language-switcher ul li > a {
  color: inherit;
}

.webkit-language-switcher ul li + li {
  margin-left: 1rem;
}

@media (max-width: 480px){
  .webkit-language-switcher ul li + li{
    margin-left: 0.5rem;
  }
}

.webkit-current-language {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-header-nav-buttons {
  display: flex;
  height: 100%;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.nav-link-right {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.nav-link-right:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.nav-link-right-home {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.nav-link-right-home:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.shadow-right {
  box-shadow: inset -23px -2px 24px -35px rgba(0, 0, 0, 0.26);
}

.webkit-header-button-big {
  display: flex;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  width: auto;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-header-button-big:hover {
  color: black;
}

.webkit-header-button-big.active {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
}

.webkit-header-button-big.active div {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-header-button-big.active-mobile {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.webkit-header-button-big.active-mobile div {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-header-button-small {
  display: flex;
  height: 100%;
  width: 3rem;
}

@media (max-width: 480px){
  .webkit-header-button-small{
    width: 2rem;
  }
}

.webkit-header-button-small{
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
}

.header-wrap-right-navi {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
}

.close-icon {
  position: absolute;
  right: 20px;
  background: transparent;
  z-index: 100;
}

.webkit-header-desktop-nav {
  display: inline-block;
  align-self: center;
  justify-self: end;
  padding-right: 1rem;
  grid-column: span 17 / span 17;
  grid-column-start: 5;
}

@media (max-width: 1600px){
  .webkit-header-desktop-nav{
    padding-right: 0px;
    grid-column: span 16 / span 16;
    grid-column-start: 5;
  }
}

.webkit-header-desktop-item {
  display: inline-block;
}

.webkit-header-desktop-item + .webkit-header-desktop-item {
  margin-left: 2rem;
}

.webkit-header-desktop-link {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  display: inline-block;
  font-weight: 700;
  font-size: 1.5rem;
}

@media (max-width: 993px){
  .webkit-header-desktop-link{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-header-desktop-link{
    font-size: 1rem;
  }
}

.webkit-header-desktop-link:after {
  content: "";
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  opacity: 0;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(141, 23, 47, var(--tw-border-opacity));
  border-bottom-width: 2px;
}

@media (min-width: 994px) {
  .webkit-header-desktop-link:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 23, 47, var(--tw-text-opacity));
  }

  .webkit-header-desktop-link:hover:after {
    opacity: 1;
  }
}

.webkit-header-desktop-item-list {
  display: inline-block;
}

.webkit-header-desktop-activeLink {
  display: inline-block;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.webkit-header-desktop-activeLink:hover:after {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.webkit-header-desktop-activeLink:after {
  content: "";
  opacity: 1;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(141, 23, 47, var(--tw-border-opacity));
  border-top-width: 2px;
}

.webkit-header-desktop-nav .webkit-language-switcher {
  display: inline-block;
  margin-right: 0px;
  margin-left: 2.5rem;
}

@media (min-width: 0px) {
  .webkit-header-desktop-nav {
    display: none;
  }
}

.webkit-header-nav-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 37.5%;
  right: -37.5%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  transition: right 0.2s ease, opacity 0.3s ease-out;
}

@media (max-width: 1280px) {
  .webkit-header-nav-overlay {
    width: 45%;
    right: -45%;
  }
}

@media (max-width: 993px) {
  .webkit-header-nav-overlay {
    width: 58%;
    right: -58%;
  }
}

@media (max-width: 768px) {
  .webkit-header-nav-overlay {
    width: 75%;
    right: -75%;
  }
}

@media (max-width: 480px) {
  .webkit-header-nav-overlay {
    width: 100%;
    right: -100%;
  }
}

.webkit-header-nav-overlay.overlay-active {
  z-index: 5;
  opacity: 1;
  right: 0;
}

.webkit-header-nav-overlay-grid {
  display: grid;
  height: 100%;
  grid-template-rows: -webkit-max-content -webkit-max-content 1fr -webkit-max-content;
  grid-template-rows: max-content max-content 1fr max-content;
}

.webkit-header-nav-overlay-close {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  grid-column: span 1 / span 1;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  justify-self: end;
  padding-top: 0.75rem;
  padding-right: 0.75rem;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 1.5rem;
}

.webkit-language-switcher-container-mobile {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  margin-top: 2rem;
  margin-left: 5rem;
}

@media (max-width: 480px){
  .webkit-language-switcher-container-mobile{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: 3rem;
  }
}

.webkit-header-nav-item-list {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  grid-column: span 1 / span 1;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 3;
  margin-top: 0px;
  margin-left: 2rem;
}

.webkit-header-nav-item + .webkit-header-nav-item {
  padding-top: 1.5rem;
}

.webkit-nav-overlay-socials {
  padding-left: 3.5rem;
  width: 100%;
  grid-row: span 1 / span 1;
  grid-row-start: 4;
  align-self: center;
  justify-self: start;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-top-width: 1px;
}

@media (max-width: 480px){
  .webkit-nav-overlay-socials{
    justify-self: start;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.webkit-header-navigation-activeLink {
  display: inline-block;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.webkit-header-navigation-activeLink:hover:after {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.webkit-header-navigation-activeLink:after {
  content: "";
  opacity: 1;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-bottom-width: 1px;
}

.webkit-nav-overlay-contact {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  vertical-align: middle;
}

.webkit-nav-overlay-contact-item {
  display: inline-block;
  padding-left: 1.5rem;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-nav-overlay-contact-item i:before {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-nav-overlay-social-media {
  display: inline-block;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.5rem;
}

.webkit-nav-overlay-social-media a {
  display: inline-block;
}

.webkit-nav-overlay-social-media a + a {
  margin-left: 1rem;
}

header {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  align-items: center;
  position: fixed;
  top: 1rem;
  width: 100%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  height: 50px;
  z-index: 9999;
}

.header-spacer {
  width: 100%;
  margin-bottom: 50px;
}

.shadow-container {
  height: 100%;
  width: 91.666667%;
}

@media (max-width: 768px){
  .shadow-container{
    width: 100%;
  }
}

.shadow-container{
  grid-column: span 24 / span 24;
  grid-column-start: 3;
}

@media (max-width: 768px){
  .shadow-container{
    grid-column: span 24 / span 24;
    grid-column-start: 3;
  }
}

.shadow-container{
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.header-logo-container {
  float: left;
  flex-shrink: 0;
  flex-grow: 0;
}

.header-nav-container {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  height: 100%;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  float: left;
  width: 91.666667%;
  display: flex;
}

.header-button-container {
  height: 100%;
}

.header-button-grid {
  display: grid;
  align-items: center;
  float: right;
  height: 100%;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content max-content;
}

@media (max-width: 1250px) {
  .header-button-grid {
    grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content max-content max-content max-content;
  }
}

.header-button-square {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: inline-block;
  height: 100%;
  position: relative;
  width: 3rem;
}

@media (max-width: 480px){
  .header-button-square{
    width: 2.5rem;
  }
}

@media (max-width: 343px){
  .header-button-square{
    width: 1.75rem;
  }
}

.header-button-square:hover {
  /* @apply border-l border-color2; */
}

.header-button-square:hover i.fa-search {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.header-button-square i {
  position: absolute;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  left: 50%;
  top: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  padding-top: 1px;
  padding-right: 1px;
}

@media (max-width: 768px) {
  .header-button-square:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
    border-left-width: 0px;
  }

  .header-button-square:hover i.fa-search {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.header-button-big {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  display: inline-block;
  height: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  font-size: 1.5rem;
}

@media (max-width: 993px){
  .header-button-big{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .header-button-big{
    font-size: 1rem;
  }
}

.header-button-big{
  display: flex;
  cursor: pointer;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.header-button-big:hover .header-button-text {
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

.header-button-big .header-button-text {
  font-weight: 700;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  align-self: center;
}

.header-nav {
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
}

@media (max-width: 1250px) {
  .header-nav {
    display: none;
  }
}

.header-nav-item {
  cursor: pointer;
  display: inline-block;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  margin-left: 1rem;
}

.header-nav-item + .header-nav-item {
  margin-left: 2rem;
}

.header-nav-item.overlay-showing::after {
  position: absolute;
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  border-radius: 0.125rem;
  display: block;
  height: 0.25rem;
  width: 1.5rem;
  content: "";
}

.header-nav-item:hover {
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

.language-dropdown {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 0.5rem;
  position: absolute;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.language-item + .language-item {
  padding-top: 0.5rem;
}

.mobile-nav-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  cursor: pointer;
  display: none;
}

@media (max-width: 1250px) {
  .mobile-nav-icon {
    display: block;
  }

  .mobile-nav-icon i {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
}

.overlay.overlay-navigation-desktop {
  top: 0px;
  overflow: visible !important;
  margin-top: 70px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s, z-index 0s linear 0.2s;
}

.overlay.overlay-navigation-desktop.overlay-show {
  z-index: 10000;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s, z-index 0s linear 0s;
}

.overlay.overlay-navigation-desktop a.active {
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

.overlay.overlay-navigation-desktop > .overlay-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 1rem;
  margin-top: 0.5rem;
  position: relative;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  grid-column: span 18 / span 18;
  grid-column-start: 4;
  border-top: 1px solid #f3f3f3;
}

.overlay.overlay-navigation-desktop > .overlay-inner.informationen-nav {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.overlay.overlay-navigation-desktop > .overlay-inner.informationen-nav a {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.overlay.overlay-navigation-desktop > .overlay-inner.informationen-nav a:hover{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.overlay.overlay-navigation-desktop .nav-overlay-outer-grid {
  display: grid;
  grid-template-columns: repeat(22, 1fr);
}

.overlay.overlay-navigation-desktop .nav-overlay-cell {
  grid-column: span 16 / span 16;
  grid-column-start: 4;
}

@media (max-width: 1600px){
  .overlay.overlay-navigation-desktop .nav-overlay-cell{
    grid-column: span 17 / span 17;
    grid-column-start: 4;
  }
}

.overlay.overlay-navigation-desktop .nav-overlay-innner-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  -moz-column-gap: 4rem;
       column-gap: 4rem;
  row-gap: 1.25rem;
}

.overlay.overlay-navigation-desktop .nav-overlay-group-title {
  display: flex;
  font-weight: 700;
  height: 2rem;
  font-size: 1.5rem;
  padding-bottom: 0.25rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.overlay.overlay-navigation-desktop .nav-overlay-group a {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.overlay.overlay-navigation-desktop .nav-overlay-group a:hover {
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

.overlay.overlay-navigation-desktop .nav-overlay-group a:hover > i {
  --tw-text-opacity: 1;
  color: rgba(134, 122, 103, var(--tw-text-opacity));
}

.overlay.overlay-navigation-desktop .informationen-nav .nav-overlay-group a {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.overlay.overlay-navigation-desktop .informationen-nav .nav-overlay-group a:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.overlay.overlay-navigation-desktop .informationen-nav .nav-overlay-group a:hover > i {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.overlay.overlay-navigation-mobile {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  align-items: center;
  position: fixed;
  top: 4rem;
  width: 100%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  height: 50px;
  overflow: visible !important;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s, z-index 0s linear 0.2s;
  /*  @screen s {
     @apply left-;
   } */
}

.overlay.overlay-navigation-mobile.overlay-show {
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s, z-index 0s linear 0s;
}

.overlay.overlay-navigation-mobile > .overlay-inner {
  grid-column: span 22 / span 22;
  grid-column-start: 3;
  padding: 0px;
  position: relative;
  min-height: 100% !important;
  width: 100% !important;
}

@media (max-width: 768px){
  .overlay.overlay-navigation-mobile > .overlay-inner{
    grid-column: span 22 / span 22;
    grid-column-start: 3;
  }
}

@media (max-width: 768px) {
  .overlay.overlay-navigation-mobile > .overlay-inner {
    min-height: 100% !important;
    width: 100% !important;
  }
}

.overlay.overlay-navigation-mobile > .overlay-inner > .overlay-inner-container {
  display: block;
  position: absolute;
  left: 0;
  right: 1rem;
  border-radius: 0.75rem;
  padding: 2rem;
}

@media (max-width: 768px){
  .overlay.overlay-navigation-mobile > .overlay-inner > .overlay-inner-container{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.overlay.overlay-navigation-mobile > .overlay-inner > .overlay-inner-container{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: auto !important;
}

.overlay.overlay-navigation-mobile > .overlay-inner .overlay-inner-container {
  height: 100vh !important;
  overflow-y: scroll !important;
}

.overlay.overlay-navigation-mobile .nav-text-icon-container {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  cursor: pointer;
  display: grid;
  grid-template-columns: minmax(0, -webkit-max-content) minmax(0, 1fr);
  grid-template-columns: minmax(0, max-content) minmax(0, 1fr);
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;
}

.overlay.overlay-navigation-mobile .nav-text-two-icon-container {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  cursor: pointer;
  display: grid;
  grid-template-columns: 20px minmax(0, -webkit-max-content) minmax(20px, 1fr);
  grid-template-columns: 20px minmax(0, max-content) minmax(20px, 1fr);
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;
}

.overlay.overlay-navigation-mobile .hyphens {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.overlay-inner-container {
  height: 100vh !important;
  overflow-y: scroll !important;
}

.webkit-footer-container {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 122, 103, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  position: relative;
}

.webkit-footer {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-footer-cell {
  grid-column-start: 3;
  grid-column-end: 23;
}

.webkit-main-footer {
  display: grid;
  padding-bottom: 4.166666666666666%;
  padding-top: 8.333333333333332%;
  grid-template-columns: repeat(24, 1fr);
}

@media (max-width: 993px){
  .webkit-main-footer{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

@media (max-width: 480px){
  .webkit-main-footer{
    padding-bottom: 8.333333333333332%;
    padding-top: 12.5%;
  }
}

.webkit-footer-module-logo-text {
  grid-column: span 7 / span 7;
  grid-column-start: 1;
}

@media (max-width: 993px){
  .webkit-footer-module-logo-text{
    grid-column: span 6 / span 6;
    grid-column-start: 1;
  }
}

@media (max-width: 480px){
  .webkit-footer-module-logo-text{
    grid-row: span 1 / span 1;
    grid-row-start: 1;
  }
}

@media (max-width: 768px){
  .webkit-footer-module-logo-text{
    padding-bottom: 8.333333333333332%;
    grid-column: span 24 / span 24;
    grid-column-start: 1;
  }
}

.webkit-footer-module-nav {
  grid-column: span 4 / span 4;
  grid-column-start: 16;
}

@media (max-width: 1600px){
  .webkit-footer-module-nav{
    grid-column: span 4 / span 4;
    grid-column-start: 14;
  }
}

@media (max-width: 1280px){
  .webkit-footer-module-nav{
    grid-column: span 4 / span 4;
    grid-column-start: 10;
  }
}

@media (max-width: 993px){
  .webkit-footer-module-nav{
    padding-bottom: 1rem;
    grid-column: span 5 / span 5;
    grid-column-start: 9;
  }
}

@media (max-width: 768px){
  .webkit-footer-module-nav{
    grid-column: span 10 / span 10;
    grid-column-start: 1;
  }
}

@media (max-width: 480px){
  .webkit-footer-module-nav{
    border-top-width: 1px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    grid-column: span 24 / span 24;
    grid-column-start: 1;
  }
}

@media (max-width: 768px){
  .webkit-footer-module-nav li{
    margin-top: 0.25rem;
  }
}

.webkit-footer-module-nav li{
  display: block;
  line-height: 1em;
}

@media (max-width: 480px) {
  .webkit-footer-module-nav li {
    line-height: 0.75em;
  }
}

.webkit-footer-module-nav li:hover {
  opacity: 1;
  color: white;
}

.webkit-footer-module-nav li a {
  opacity: 0.4;
}

.webkit-footer-module-nav li a:hover {
  opacity: 1;
  color: white;
}

.webkit-footer-module-nav li + li {
  padding-top: 0.75rem;
}

@media (max-width: 768px){
  .webkit-footer-module-nav li + li{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

.webkit-footer-module-contact {
  grid-column: span 5 / span 5;
  grid-column-start: 20;
}

@media (max-width: 1600px){
  .webkit-footer-module-contact{
    grid-column: span 7 / span 7;
    grid-column-start: 18;
  }
}

@media (max-width: 1280px){
  .webkit-footer-module-contact{
    grid-column: span 9 / span 9;
    grid-column-start: 16;
  }
}

@media (max-width: 993px){
  .webkit-footer-module-contact{
    grid-column: span 11 / span 11;
    grid-column-start: 15;
  }
}

@media (max-width: 768px){
  .webkit-footer-module-contact{
    grid-column: span 12 / span 12;
    grid-column-start: 12;
  }
}

@media (max-width: 480px){
  .webkit-footer-module-contact{
    grid-column: span 24 / span 24;
    grid-column-start: 1;
  }
}

.webkit-footer-heading {
  padding-bottom: 0.75rem;
}

@media (max-width: 480px){
  .webkit-footer-heading{
    padding-bottom: 0.25rem;
  }
}

.webkit-footer-heading{
  grid-column-start: 3;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-footer-kontakt-text {
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 1rem;
  opacity: 0.4;
}

@media (max-width: 768px) {
  .webkit-footer-kontakt-text {
    font-size: 0.75rem;
  }
}

.webkit-footer-kontakt-link {
  font-weight: 700;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-footer-kontakt-link:hover {
  color: white;
}

@media (max-width: 768px) {
  .webkit-footer-kontakt-link {
    font-size: 0.75rem;
  }
}

.webkit-footer-kontakt-icon {
  font-weight: 700;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-footer-logoText {
  font-weight: 300;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 768px) {
  .webkit-footer-logoText {
    font-size: 0.75rem;
  }
}

.webkit-footer-nav-link {
  font-weight: 300;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 768px) {
  .webkit-footer-nav-link {
    font-size: 0.75rem;
  }
}

.webkit-footer-nav-externalLink {
  font-weight: 300;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.webkit-footer-nav-activeLink {
  font-size: 1rem;
  opacity: 1;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-footer-image {
  padding-bottom: 0.5rem;
  height: auto;
  width: 60%;
}

@media (max-width: 1280px) {
  .webkit-footer-image {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .webkit-footer-image {
    width: 60%;
  }
}

.webkit-sub-footer-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  padding-bottom: 6%;
  border-top-width: 1px;
  padding-top: 4.166666666666666%;
}

@media (max-width: 1280px){
  .webkit-sub-footer-container{
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-sub-footer-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-sub-footer-module-socials{
    order: -9999;
    padding-top: 0.25rem;
    padding-bottom: 4.166666666666666%;
  }
}

.webkit-sub-footer-module-nav {
  grid-column: span 12 / span 12;
  grid-column-start: 1;
  grid-row: span 2 / span 2;
  grid-row-start: 1;
}

@media (max-width: 768px){
  .webkit-sub-footer-module-nav{
    grid-column: span 24 / span 24;
    grid-column-start: 1;
  }
}

@media (max-width: 768px){
  .webkit-sub-footer-module-nav{
    grid-row: span 1 / span 1;
    grid-row-start: 1;
  }
}

@media (max-width: 768px){
  .webkit-sub-footer-module-nav{
    padding-top: 4.166666666666666%;
  }
}

.webkit-sub-footer-module-nav{
  align-self: flex-end;
  justify-self: start;
}

.binary-backlink {
  display: none !important;
  font-weight: 400;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .binary-backlink{
    font-size: .75rem;
  }
}

@media (max-width: 480px){
  .binary-backlink{
    font-size: .75rem;
  }
}

.binary-backlink{
  grid-column: span 8 / span 8;
  grid-column-start: 9;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
}

@media (max-width: 1280px){
  .binary-backlink{
    grid-column: span 24 / span 24;
    grid-column-start: 1;
  }
}

@media (max-width: 1280px){
  .binary-backlink{
    padding-top: 4.166666666666666%;
    grid-row: span 1 / span 1;
    grid-row-start: 2;
  }
}

@media (max-width: 768px){
  .binary-backlink{
    padding-top: 8.333333333333332%;
    grid-row: span 1 / span 1;
    grid-row-start: 3;
  }
}

@media (max-width: 480px){
  .binary-backlink{
    padding-bottom: 0.5rem;
  }
}

.binary-backlink{
  display: inline-block;
  align-self: flex-end;
  justify-self: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  opacity: 0.4;
}

.webkit-sub-footer-module-socials {
  grid-column: span 16 / span 16;
  grid-column-start: 18;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
}

@media (max-width: 768px){
  .webkit-sub-footer-module-socials{
    grid-column: span 24 / span 24;
    grid-column-start: 1;
  }
}

.webkit-sub-footer-module-socials{
  align-self: flex-end;
  justify-self: end;
}

@media (max-width: 768px){
  .webkit-sub-footer-module-socials{
    justify-self: start;
  }
}

.webkit-sub-footer-nav {
  display: flex;
}

.webkit-social-media-icons {
  height: 1rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  width: 1rem;
  --tw-text-opacity: 1;
  color: rgba(141, 23, 47, var(--tw-text-opacity));
}

.webkit-sub-footer-nav-item {
  margin-right: 0.75rem;
}

.webkit-subfooter-link-internal {
  font-weight: 400;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-subfooter-link-internal{
    font-size: .75rem;
  }
}

@media (max-width: 480px){
  .webkit-subfooter-link-internal{
    font-size: .75rem;
  }
}

.webkit-subfooter-link-internal{
  color: white;
  opacity: 0.2;
}

.webkit-subfooter-link-internal:hover {
  color: white;
  opacity: 1;
}

.webkit-subfooter-link-external {
  font-weight: 400;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-subfooter-link-external{
    font-size: .75rem;
  }
}

@media (max-width: 480px){
  .webkit-subfooter-link-external{
    font-size: .75rem;
  }
}

.webkit-subfooter-link-external{
  opacity: 0.4;
}

.active-page-footer {
  opacity: 1 !important;
}

.bg-img__bg, .bg-img__square, .bg-img__2-1, .bg-img__4-3, .bg-img__16-10, .bg-img__16-9, .bg-img, .webkit-intro-background-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img__contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.img-hidden {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}

.bg-img__16-9 {
  padding-top: 56.25%;
}

.bg-img__16-10 {
  padding-top: 62.5%;
}

.bg-img__4-3 {
  padding-top: 75%;
}

.bg-img__2-1 {
  padding-top: 50%;
}

.bg-img__square {
  padding-top: 100%;
  border-radius: 1rem;
}

.bg-img__round {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 666px;
  padding-top: 100%;
}

.bg-img__center-top {
  background-position: top;
}

.bg-img__bg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  opacity: 0.5;
}

.webkit-intro-container {
  display: grid;
  height: 100%;
  padding-bottom: 4.166666666666666%;
  position: relative;
  grid-template-columns: repeat(24, 1fr);
  margin-top: -50px;
}

@media (max-width: 768px) {
  .webkit-intro-container {
    margin-top: -50px;
  }
}

.webkit-slider-header {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
}

.webkit-slider-header .webkit-header {
  background-color: transparent;
  position: relative;
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding-top: 1.5rem;
}

@media (max-width: 993px){
  .webkit-slider-header .webkit-header{
    padding-top: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-slider-header .webkit-header{
    padding-top: 0.5rem;
  }
}

.webkit-slider-header .webkit-header .webkit-header-nav-overlay-grid {
  height: 100vh;
}

.webkit-slider-header .webkit-header .webkit-header-button {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-slider-header .webkit-header .webkit-menu-icon i:before {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-intro-background-image {
  padding-top: 2.5rem;
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row: span 3 / span 3;
  grid-row-start: 1;
  width: 100%;
  height: 95vh;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .webkit-intro-background-image {
    background-position: 20%;
    height: 100%;
  }
}

.webkit-intro-background {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.25;
}

.webkit-intro-text-placeholder {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
}

.webkit-intro-grid-text-container {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-intro-text-container {
  align-self: center;
  justify-self: center;
  margin-bottom: 3.5rem;
  margin-top: 6rem;
  text-align: center;
  grid-column: span 10 / span 10;
  grid-column-start: 4;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
}

@media (max-width: 993px){
  .webkit-intro-text-container{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 768px){
  .webkit-intro-text-container{
    grid-column: span 22 / span 22;
    grid-column-start: 2;
  }
}

.webkit-intro-text-container{
  text-align: left;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-left: 0px;
}

@media (min-width: 1601px){
  .webkit-intro-text-container{
    margin-left: -1.5rem;
  }
}

@media (max-width: 1600px){
  .webkit-intro-text-container{
    margin-left: 0.75rem;
  }
}

@media (max-width: 993px){
  .webkit-intro-text-container{
    margin-left: 0px;
  }
}

@media (max-width: 768px){
  .webkit-intro-text-container{
    margin-top: 20rem;
  }
}

.webkit-intro-text-container{
  background-color: rgba(246,248,250,0.63529);
}

@media (min-width: 1601px) {
  .webkit-intro-text-container {
    justify-self: start;
    margin-left: -1.25rem;
    max-width: 70%;
  }
}

.webkit-intro-heading {
  font-weight: 700;
  font-size: 3.4375rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-intro-heading{
    font-size: 2.5rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-heading{
    font-size: 1.875rem;
  }
}

.webkit-intro-untertitel {
  font-weight: 300;
  font-weight: 700;
  font-size: 3.4375rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-intro-untertitel{
    font-size: 2.5rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-untertitel{
    font-size: 1.875rem;
  }
}

.webkit-intro-untertitel{
  /*   @apply sm:text-xl; */
  margin-bottom: 1rem;
  line-height: 2.5rem;
}

.webkit-intro-text {
  font-weight: 400;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-weight: 400;
  margin-bottom: 1rem;
  position: relative;
  top: 500px;
}

.main-grid-structure {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
}

.webkit-grid-container-cell {
  grid-column: span 20 / span 20;
  grid-column-start: 3;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.custom-text-size1 {
  font-size: 3.4375rem;
}

@media (max-width: 1280px){
  .custom-text-size1{
    font-size: 2.5rem;
  }
}

@media (max-width: 768px){
  .custom-text-size1{
    font-size: 1.875rem;
  }
}

.custom-text-size2 {
  font-size: 1.75rem;
}

@media (max-width: 1280px){
  .custom-text-size2{
    font-size: .1.5625rem;
  }
}

@media (max-width: 768px){
  .custom-text-size2{
    font-size: 1.25rem;
  }
}

.custom-text-size3 {
  font-size: 1.5rem;
}

@media (max-width: 1280px){
  .custom-text-size3{
    font-size: 1.125rem;
  }
}

@media (max-width: 768px){
  .custom-text-size3{
    font-size: 1.125rem;
  }
}

.custom-text-size4 {
  font-size: 1.25rem;
}

@media (max-width: 1280px){
  .custom-text-size4{
    font-size: .7rem;
  }
}

@media (max-width: 768px){
  .custom-text-size4{
    font-size: 1rem;
  }
}

.custom-text-size5 {
  font-size: 1.5rem;
}

@media (max-width: 1280px){
  .custom-text-size5{
    font-size: 1rem;
  }
}

@media (max-width: 768px){
  .custom-text-size5{
    font-size: 1rem;
  }
}

.webkit-intro-action-background-image {
  height: 75vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.intro-grid-text {
  display: grid;
  height: 100%;
  position: relative;
  grid-template-columns: repeat(24, 1fr);
  margin-top: -100px;
}

.webkit-intro-action-text {
  grid-column: span 11 / span 11;
  grid-column-start: 3;
  --tw-bg-opacity: 1;
  background-color: rgba(141, 23, 47, var(--tw-bg-opacity));
  padding: 2rem;
  padding-bottom: 2rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  transform: translateY(-40%);
}

@media (max-width: 1600px) {
  .webkit-intro-action-text {
    grid-column: span 15 / span 15;
    grid-column-start: 3;
  }
}

@media (max-width: 1280px) {
  .webkit-intro-action-text {
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

@media (max-width: 993px) {
  .webkit-intro-action-text {
    grid-column: span 20 / span 20;
    grid-column-start: 3;
    transform: translateY(-15%);
  }
}

.webkit-intro-action-text h1 {
  font-weight: 700;
  font-size: 3.4375rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-intro-action-text h1{
    font-size: 2.5rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-action-text h1{
    font-size: 1.875rem;
  }
}

.webkit-intro-action-text .company-subheading {
  font-weight: 300;
  font-weight: 700;
  font-size: 3.4375rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px){
  .webkit-intro-action-text .company-subheading{
    font-size: 2.5rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-action-text .company-subheading{
    font-size: 1.875rem;
  }
}

.webkit-intro-action-text .company-subheading{
  font-weight: 200 !important;
}

.webkit-intro-action-text p {
  font-weight: 400;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-intro-action-outer-grid {
  height: 100%;
}

.navi-sidebar {
  font-size: 1.5rem;
}

.webkit-slideshow-container, .webkit-slide-image {
  height: 90vh;
}

body {
  overflow-x: hidden;
}

body#old-browsers .webkit-header {
  position: static;
}

body#old-browsers .browsers-list {
  display: inline-block;
  width: auto;
  margin: 1.5rem auto;
  -moz-columns: 2;
  columns: 2;
  list-style: none;
}

body#old-browsers .browsers-list > li {
  display: block;
  text-align: left;
  padding: 0;
}

body#old-browsers .browsers-list > li a {
  display: block;
  padding: 0.5rem;
  overflow: hidden;
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s;
}

body#old-browsers .browsers-list > li a:hover {
  background-color: rgba(0, 0, 0, 0.16);
}

body#old-browsers .browsers-list > li + li {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  body#old-browsers .browsers-list {
    -moz-columns: 1;
    columns: 1;
  }
}

body#old-browsers .browser-logo {
  display: inline-block;
  width: 64px;
  height: auto;
  margin-right: 1rem;
  vertical-align: middle;
}

body#old-browsers .webkit-header,
body#old-browsers .webkit-text,
body#old-browsers .webkit-main-footer,
body#old-browsers .webkit-footer,
body#old-browsers .webkit-heading {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

body#old-browsers .webkit-header-logo {
  -ms-grid-column: 2;
  -ms-grid-column-span: 8;
}

body#old-browsers .webkit-text-cell {
  -ms-grid-column: 5;
  -ms-grid-column-span: 14;
}

body#old-browsers .webkit-footer-module-contact {
  -ms-grid-column: 19;
  -ms-grid-column-span: 4;
}

body#old-browsers .webkit-footer-cell {
  -ms-grid-column: 3;
  -ms-grid-column-span: 20;
}

body#old-browsers .webkit-footer-module-logo-text {
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
}

body#old-browsers .webkit-heading-cell {
  -ms-grid-column: 5;
  -ms-grid-column-span: 14;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(https://cdn.jsdelivr.net/npm/lightbox2@2.11.3/dist/images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev,
.lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  position: relative;
  width: 34%;
  float: left;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev i {
  position: absolute;
  font-size: 1.5rem;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  top: 50%;
  left: -1.75rem;
  bottom: auto;
  transform: translateY(-50%);
}

@media (max-width: 480px){
  .lb-nav a.lb-prev i{
    display: none;
  }
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  position: relative;
  width: 64%;
  float: right;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next i {
  position: absolute;
  font-size: 1.5rem;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  top: 50%;
  right: -1.75rem;
  bottom: auto;
  transform: translateY(-50%);
}

@media (max-width: 480px){
  .lb-nav a.lb-next i{
    display: none;
  }
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav-mobile {
  display: none !important;
}

@media (max-width: 480px){
  .lb-nav-mobile{
    display: block !important;
  }
}

.lb-nav-mobile{
  position: relative;
  margin: 0 auto;
  padding-top: 1.25rem;
  cursor: pointer;
}

.lb-prev-mobile {
  position: absolute;
  display: inline-block;
  left: 0;
  width: 34%;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.lb-next-mobile {
  position: absolute;
  display: inline-block;
  right: 0;
  width: 66%;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: right;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer-close {
  position: absolute;
  top: -1.8rem;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  padding-top: 5px;
  zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after,
.lb-dataContainer-close:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

@media (max-width: 480px){
  .lb-data{
    padding: 0px;
  }
}

.lb-data .lb-details {
  width: 100%;
  float: left;
  text-align: left;
  line-height: 1em;
}

@media (max-width: 480px) {
  .lb-data .lb-details {
    line-height: 0.7em;
  }
}

.lb-data .lb-caption {
  font-weight: 400;
  font-size: 1.5rem;
}

@media (max-width: 993px){
  .lb-data .lb-caption{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .lb-data .lb-caption{
    font-size: 1rem;
  }
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

@media (max-width: 480px){
  .lb-closeContainer{
    padding-bottom: 2rem;
  }
}

.lb-data .lb-close {
  display: block;
  right: 0;
  float: right;
  width: 30px;
  height: 30px;
  margin-right: -2rem;
}

@media (max-width: 480px){
  .lb-data .lb-close{
    margin-right: 0px;
  }
}

.lb-data .lb-close{
  background: url(https://cdn.jsdelivr.net/npm/lightbox2@2.11.3/dist/images/close.png) top right no-repeat;
  background-size: 60%;
  text-align: right;
  outline: none;
  opacity: 1;
  cursor: pointer;
}

@media (max-width: 768px) {
  .lb-data .lb-close {
    background-size: 75%;
  }
}

.overlay {
  overflow: hidden;
  position: fixed;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s;
  pointer-events: none;
}

.overlay.overlay-show {
  pointer-events: all;
  overflow-y: scroll;
  z-index: 10000;
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s;
}

.overlay .overlay-inner {
  padding-top: 0.7rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}