.bone-input-text {
  position: relative;
}
.bone-input-text.bone-input-text-filled .bone-input-text-label,
.bone-input-text .bone-input-text-input:focus ~ .bone-input-text-label,
.bone-input-text .bone-input-text-input[type=date] ~ .bone-input-text-label {
  transform: translateY(-175%);
  font-size: 10px;
}
.bone-input-text .bone-input-text-input:focus {
  outline: none;
}
.bone-input-text .bone-instruction,
.bone-input-text .bone-error {
  padding: 0.5rem;
}
.bone-input-text .bone-error {
  color: red;
}
.bone-input-text-inner {
  position: relative;
}
.bone-input-text-input {
  height: 52px;
  width: 100%;
  padding: 0 0.5rem;
  font-size: 14px;
}
.bone-input-text-input:-webkit-autofill {
  -webkit-animation-name: onAutoFillStart;
  animation-name: onAutoFillStart;
}
.bone-input-text-input:not(:-webkit-autofill) {
  -webkit-animation-name: onAutoFillCancel;
  animation-name: onAutoFillCancel;
}
.bone-input-text-input:disabled ~ .bone-input-text-label {
  color: lightgray;
}
.bone-input-text-label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 0 0.5rem;
  font-size: 14px;
  font-weight: inherit;
  font-family: inherit;
  line-height: 1.25em;
  pointer-events: none;
  transition: transform 0.2s, font-size 0.2s;
}
@-webkit-keyframes onAutoFillStart {
}
@keyframes onAutoFillStart {
}
@-webkit-keyframes onAutoFillCancel {
}
@keyframes onAutoFillCancel {
}